import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import getEnv from './getEnv';
import getValue from './getValue';
import { Environment, getCurrentEnvironment } from './onEnvironment';

export function initSentry(): void {
    Sentry.init({
        dsn: getEnv('SENTRY_DSN'),
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
        ],
        tracesSampleRate: 1.0,
        environment: getValue(() => {
            const environment = getCurrentEnvironment();

            switch (environment) {
                case Environment.Production:
                    return 'production';
                case Environment.Staging:
                    return 'staging';
                case Environment.Local:
                    return 'local';

                default:
                    throw new Error(`Unhandled environment "${environment}"`);
            }
        }),
    });
}

export type ErrorNumber = string;

// TODO: Set/clear context somewhere

export function setUserContext(context: UserContext): void {
    Sentry.setUser({
        id: context.userId.toString(),
        email: context.emailAddress,
    });
}

export function clearUserContext(): void {
    Sentry.setUser(null);
}

export default function report(error: Error): ErrorNumber {
    const errorNumber = Sentry.captureException(error);

    console.error(`Sentry error ${errorNumber}:`, error);

    return errorNumber;
}

export interface UserContext {
    userId: number;
    emailAddress: string;
}
