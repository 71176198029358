import { NumberedTabs, TabPanel, NumberedTab, PrimaryButton } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import { Grid, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { AccountsTabs } from '..';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { BUTTON_CREATE_CUSTOMER, BUTTON_CREATE_HOTEL, SCREEN_ACCOUNTS_PAGE } from '../../../amplitude/amplKeys';
import { ACCOUNTS_CUSTOMERS, ACCOUNTS_HOTELS, CREATE_CUSTOMER, CREATE_HOTEL } from '../../../constans/urlPaths';
import { logAmplitudeAccountsTab } from '../../../helpers/logAmplitudeTab';
import CustomersPanel from './customersPanel/CustomersPanel';
import HotelsPanel from './hotelsPanel/HotelsPanel';

interface AccountsPageProps {
    tab: AccountsTabs;
}

const AccountsContent = ({ tab }: AccountsPageProps) => {
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [totalHotels, setTotalHotels] = useState(0);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    useEffect(() => {
        logAmplitudeEvent(SCREEN_ACCOUNTS_PAGE);
    }, []);

    useEffect(() => {
        logAmplitudeAccountsTab(tab);
    }, [tab]);

    const handleCreate = () => {
        if (tab === AccountsTabs.Hotels) {
            logAmplitudeEvent(BUTTON_CREATE_HOTEL);
            navigate(CREATE_HOTEL);
            return;
        }

        logAmplitudeEvent(BUTTON_CREATE_CUSTOMER);
        navigate(CREATE_CUSTOMER);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={10} lg={10} xl={10}>
                    <NumberedTabs value={tab}>
                        <NumberedTab
                            number={totalHotels}
                            label="Hotels"
                            url={ACCOUNTS_HOTELS}
                            index={AccountsTabs.Hotels}
                            selectedIndex={tab}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={totalCustomers}
                            label="Customers"
                            url={ACCOUNTS_CUSTOMERS}
                            index={AccountsTabs.Customers}
                            selectedIndex={tab}
                            isHeighterNumberHidden
                        />
                    </NumberedTabs>
                </Grid>
                <Grid item xs={12} md={2} lg={2} xl={2} sx={[{ textAlign: 'right' }, isMobile && { paddingTop: '2rem' }]}>
                    <PrimaryButton onClick={handleCreate} icon={<AddIcon />}>
                        Create
                    </PrimaryButton>
                </Grid>
            </Grid>
            <TabPanel value={AccountsTabs.Hotels} selectedValue={tab}>
                <HotelsPanel isActive={tab === AccountsTabs.Hotels} setTotalHotels={setTotalHotels} />
            </TabPanel>
            <TabPanel value={AccountsTabs.Customers} selectedValue={tab}>
                <CustomersPanel isActive={tab === AccountsTabs.Customers} setTotalCustomers={setTotalCustomers} />
            </TabPanel>
        </>
    );
};

export default AccountsContent;
