import { PrimaryButton } from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { FunctionComponent, PropsWithChildren } from 'react';

import PageTitle from './PageTitle';

const useStyles = makeStyles({
    retryButton: { margin: '2rem 0' },
    container: { margin: '2rem 0' },
});

const Retry: FunctionComponent<
    PropsWithChildren<{
        onRetry: () => void;
        loading?: boolean;
    }>
> = ({ onRetry, loading }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <PageTitle title="Unexpected error" />
            <div>Sorry, something went wrong on our end. Please refresh the page or contact our 24/7 support team</div>
            <PrimaryButton className={classes.retryButton} onClick={onRetry} loading={loading}>
                Retry
            </PrimaryButton>
        </div>
    );
};

export default Retry;
