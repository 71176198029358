import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchOption } from '../pages/reservations/api/types';

export interface SearchState {
    value: SearchOption | null;
}

const initialState: SearchState = {
    value: null,
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearch: (state: SearchState, action: PayloadAction<SearchOption | null>) => {
            state.value = action.payload;
        },
    },
});

export const { setSearch } = searchSlice.actions;

export default searchSlice.reducer;
