import { useQuery } from 'react-query';

import { ACTIVE_PEOPLE } from '../../../constans/queryKeys';
import { listPeople } from '../../../services/people';

export function useActive(id: number) {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([ACTIVE_PEOPLE], () => listPeople(id), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    const activePeopleData = data?.filter(el => el.isActive);

    return { activePeopleData, isLoading, isError, isFetching, refetch };
}
