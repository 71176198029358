import { User } from '../pages/people/api/types';
import apiClient from './api';
import { CSRF_COOKIE, LOGIN, LOGOUT } from './routes';

export const login = async (email: string, password: string): Promise<User> => {
    await apiClient.get(CSRF_COOKIE);

    const { data } = await apiClient.post<User>(LOGIN, {
        email,
        password,
    });

    return data;
};

export const logout = () => apiClient.post(LOGOUT);
