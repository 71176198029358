import downloadFile from '../../../helpers/downloadFile';
import apiClient from '../../../services/api';
import { getDownloadFilesRoute } from '../../../services/routes';

const downloadFiles = async (id: number): Promise<unknown> => {
    const result = await apiClient
        .get(getDownloadFilesRoute(id), { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            downloadFile(url, `GET-E Files ${id}.zip`);
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default downloadFiles;
