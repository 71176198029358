export const COLORS = {
    BOOKING_PENDING: '#2c6794',
    SEARCHING_ROOMS: '#c96826',
    BOOKING_UPDATE_PENDING: '#c96826',
    PROCESS_CANCELLATION: '#991515',
    BOOKING_CANCELLED: '#991515',
    NOT_AVAILABLE: '#c96826',
    BOOKING_CONFIRMED: '#1e9c65',
    COMPLETED: '#1e9c65',
};
