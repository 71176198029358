import axios from 'axios';
import { Environment, getCurrentEnvironment } from '../helpers/onEnvironment';

import { TIME_ZONE } from './routes';

export interface TimeZone {
    dstOffset: number;
    rawOffset: number;
    status: string;
    timeZoneId: string;
    timeZoneName: string;
}

export const getTimeZone = async (lat: string, long: string, timestamp?: number): Promise<TimeZone> => {
    const params = {
        location: `${lat},${long}`,
        timestamp,
        key: process.env.REACT_APP_GOOGLE_ADDRESS,
    };

    if (getCurrentEnvironment() === Environment.Production) {
        params.key = process.env.REACT_APP_GOOGLE_TIMEZONE_API;
    }

    const { data } = await axios.get<TimeZone>(` ${TIME_ZONE}`, {
        params,
    });

    return data;
};
