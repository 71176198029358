import { DataGrid } from '@get-e/react-components';
import { GridEventListener } from '@mui/x-data-grid';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { BUTTON_EDIT_CUSTOMER } from '../../../../amplitude/amplKeys';
import { LOCAL_STORAGE_KEYS } from '../../../../constans/localStorageKeys';
import { EDIT_CUSTOMER_BASE_URL } from '../../../../constans/urlPaths';
import { useCustomers } from '../../api/useCustomers';
import { columns } from '../../data';
import { useDataGridStyles } from '../../styles/useDataGridStyles';

interface CustomersProps {
    isActive: boolean;
    setTotalCustomers: (totalCustomers: number) => void;
}

const CustomersPanel = ({ isActive, setTotalCustomers }: CustomersProps) => {
    const { accounts, isLoading, isError } = useCustomers();
    const dataGridClasses = useDataGridStyles();
    const navigate = useNavigate();

    useEffect(() => {
        if (accounts) {
            setTotalCustomers(accounts.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts.length]);

    const handleRowClick: GridEventListener<'rowClick'> = (params, event) => {
        event.preventDefault();
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCOUNT_EDITED, params.row.name ?? '');
        navigate(`${EDIT_CUSTOMER_BASE_URL}${params.id}`, {
            state: {
                accountName: params.row.name,
            },
        });
        logAmplitudeEvent(BUTTON_EDIT_CUSTOMER);
    };

    return (
        <div className={dataGridClasses.dataGrid}>
            {isActive && (
                <DataGrid
                    className={clsx(dataGridClasses.dataGrid, !accounts.length ? dataGridClasses.dataGridNoRows : '')}
                    columns={columns()}
                    rows={accounts}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    autoHeight
                    loading={isLoading && !isError}
                    onRowClick={handleRowClick}
                    noRows="No results found"
                    noRowsClassName={dataGridClasses.noResultsWrapper}
                    hideFooter={!accounts.length}
                />
            )}
        </div>
    );
};

export default CustomersPanel;
