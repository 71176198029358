import { useQuery } from 'react-query';

import { INVITED_PEOPLE } from '../../../constans/queryKeys';
import { listInvitedPeople } from '../../../services/people';

export function useInvited(id: number) {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([INVITED_PEOPLE], () => listInvitedPeople(id), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return { data, isLoading, isError, isFetching, refetch };
}
