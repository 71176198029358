import { PrimaryButton, SecondaryButton, Form, TextField } from '@get-e/react-components';
import { Card, CardContent, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import {
    BUTTON_SAVE_CUSTOMER,
    SCREEN_CREATE_EDIT_CUSTOMER,
    INPUT_ACCOUNT_ADDRESS,
    INPUT_ACCOUNT_NAME,
} from '../../../amplitude/amplKeys';
import { GooglePlacesAutocomplete } from '../../../components/GooglePlacesAutocomplete';
import PageTitle from '../../../components/PageTitle';
import { COLORS } from '../../../constans/colors';
import { ACCOUNTS_CUSTOMERS } from '../../../constans/urlPaths';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import allValid from '../../../helpers/validation/allValid';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import isFilledObject from '../../../helpers/validation/validators/isFilledObject';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import { createCustomer } from '../../../services/customer';
import { AddressRequest } from '../api/types';

const useStyles = makeStyles({
    heading: {},
    details: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: '2rem',
    },
    detailsIcon: {
        marginRight: '1.5rem',
    },
    formContainer: {
        maxWidth: '442px',
    },
    formContent: {
        border: `1px solid ${COLORS.DARK_GRAY}`,
        padding: '2rem',
        marginBottom: '2rem',
    },
    buttonCancle: {
        marginLeft: '2rem',
    },
});

const CreateCustomer = () => {
    const autoFocusRef = useRef<HTMLInputElement>();
    const [name, setName] = useState('');
    const [address, setAddress] = useState<AddressRequest>({} as AddressRequest);
    const [addressError, setAddressError] = useState<InputError | null>(null);
    const [customerError, setCustomerError] = useState<InputError | null>(null);
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    useEffect(() => {
        logAmplitudeEvent(SCREEN_CREATE_EDIT_CUSTOMER);
    }, []);

    useEffect(() => {
        logAmplitudeEvent(INPUT_ACCOUNT_ADDRESS);
    }, [address]);

    const { mutate: createCustomerMutation, isLoading: isLoadingCreate } = useMutation(createCustomer, {
        onSuccess: () => {
            navigate(ACCOUNTS_CUSTOMERS);
            showNotification('Customer successfully created.', Severity.Info);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    const validateFormFields = (): boolean => {
        const validated = {
            name: isFilledString(name, InputError.Required),
            address: isFilledObject(address, InputError.Required),
        };

        if (!allValid(validated)) {
            setAddressError(validated.address.isValid ? null : validated.address?.error);
            setCustomerError(validated.name.isValid ? null : validated.name.error);
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        logAmplitudeEvent(BUTTON_SAVE_CUSTOMER);

        if (validateFormFields()) {
            createCustomerMutation({
                name,
                address,
            });
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <>
            <Form className={classes.formContainer} onSubmit={handleSubmit}>
                <PageTitle title="Create customer" />
                <Grid item xs={12} md={6} xl={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container columnSpacing={1} rowSpacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        value={name}
                                        onChange={event => {
                                            setName(event.target.value);
                                            setCustomerError(null);
                                        }}
                                        label="Create customer"
                                        type="text"
                                        autoFocus
                                        autoComplete="username"
                                        name="name"
                                        inputRef={autoFocusRef}
                                        required
                                        error={customerError !== null}
                                        helperText={getHelperText(customerError, t)}
                                        onFocus={() => logAmplitudeEvent(INPUT_ACCOUNT_NAME)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <GooglePlacesAutocomplete
                                        error={addressError}
                                        helperText={getHelperText(addressError, t)}
                                        onChangeError={setAddressError}
                                        onChangeAddress={setAddress}
                                        label="Address"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} marginTop="2rem">
                    <PrimaryButton onClick={() => handleSubmit()} submitsForm loading={isLoadingCreate}>
                        Create
                    </PrimaryButton>
                    <SecondaryButton onClick={() => handleCancel()} className={classes.buttonCancle}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Form>
        </>
    );
};

export default CreateCustomer;
