import { useQuery } from 'react-query';

import { INVITED_HOTEL_USERS } from '../../../constans/queryKeys';
import { listInvitedUsers } from '../../../services/hotelUsers';

export function useInvitedHotelUsers(id: number) {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([INVITED_HOTEL_USERS], () => listInvitedUsers(id), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return { data, isLoading, isError, isFetching, refetch };
}
