/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { PrimaryButton, SecondaryButton, TextField, Autocomplete } from '@get-e/react-components';
import { InfoOutlined, DeleteOutline } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { Card, CardContent, Grid, Typography, IconButton, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import LabeledValue from '../../../components/LabeledValue';
import { COLORS } from '../../../constans/colors';
import { INVOICES } from '../../../constans/urlPaths';
import { useAuth } from '../../../context/AuthenticatedUserContext';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import isFilledArray from '../../../helpers/validation/validators/isFilledArray';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import isNotNull from '../../../helpers/validation/validators/isNotNull';
import or from '../../../helpers/validation/validators/or';
import { createInvoice, updateInvoice } from '../../../services/invoice';
import { useHotels } from '../../accounts/api/useHotels';
import downloadStatement from '../../billing/api/downloadStatement';
import { Entity, Statement } from '../../billing/api/types';
import { useStatements } from '../../billing/api/useStatements';
import { deleteFileFromInvoice } from '../api/deleteInvoice';
import downloadInvoiceFile from '../api/downloadInvoiceFile';
import BookingsDetails from '../components/BookingsDetails';
import InvoiceLoadingSkeleton from '../components/InvoiceLoadingSkeleton';
import { useInvoice } from './api/useInvoice';
import { useRelationNames } from './api/useRelationNames';
import { getDifferenceIcon, getDifferenceRoomIcon, getFormattedDatePeriod } from './helpers';

const useStyles = makeStyles(() => ({
    heading: {
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
    },
    helperText: {
        color: COLORS.RED,
    },
    icon: {
        fontSize: '1.5rem',
        marginLeft: '0.5rem',
    },
    mr1: {
        marginRight: '1rem',
    },
    formField: { marginBottom: '1.5rem' },
    fileContainer: {
        backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        padding: '.5rem 1rem',
        marginRight: '1rem',
    },
    fileName: {
        color: COLORS.BLUE_TEXT,
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '1rem',
    },
}));

interface FormFields {
    invoiceReference: string;
    comments: string;
    invoiceDate: Moment | null;
    dueDate: Moment | null;
    totalAmount: number | '';
    totalAmountStatement: string;
    totalRoomNights: number | '';
    totalRoomNightsStatement: number | null;
    relationName: Entity | null;
    statement: Statement | null;
}

interface DifferenceFields {
    roomNights: string;
    amount: string;
}

interface FormErrors {
    invoiceReference: InputError | null;
    invoiceDate: InputError | null;
    dueDate: InputError | null;
    totalAmount: InputError | null;
    totalRoomNights: InputError | null;
    files: InputError | null;
}

interface BookingDetails {
    hotelName: string;
    relationName: string;
    statement: Statement;
}

const UploadInvoice = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user } = useAuth();
    const { showNotification } = useNotificationContext();
    const [searchRelationNameTerm, setSearchRelationNameTerm] = useState('');
    const [searchStatementTerm, setSearchStatementTerm] = useState('');
    const [searchHotelTerm, setSearchHotelTerm] = useState('');
    const [hotel, setHotel] = useState<Entity | null>(user?.hotel as Entity);
    const [files, setFiles] = useState<File[]>([]);
    const { id = '' } = useParams();
    const isEditDone = useRef(false);
    const [bookingDetails, setBookingDetails] = useState<BookingDetails>();
    const [filesForEdit, setFilesForEdit] = useState<Entity[]>();
    const [filesToDelete, setFilesToDelete] = useState<number[]>([]);

    const [formValues, setFormValues] = useState<FormFields>({
        invoiceReference: '',
        comments: '',
        invoiceDate: null,
        dueDate: null,
        totalAmount: '',
        totalRoomNights: '',
        relationName: null,
        statement: null,
        totalRoomNightsStatement: null,
        totalAmountStatement: '',
    });

    const [difference, setDifference] = useState<DifferenceFields>({
        roomNights: '',
        amount: '',
    });

    const [formErrors, setFormErrors] = useState<FormErrors>({
        invoiceReference: null,
        invoiceDate: null,
        dueDate: null,
        totalAmount: null,
        totalRoomNights: null,
        files: null,
    });

    const [isOpenPicker, setIsOpenPicker] = useState({
        invoiceDate: false,
        dueDate: false,
    });

    useEffect(() => {
        setDifference(prevValues => ({
            ...prevValues,
            amount: formValues.totalAmount
                ? `${Math.abs(formValues.totalAmount - (formValues?.statement?.totalAmount || 0))?.toLocaleString()} ${formValues?.statement?.currencyIso ?? ''}`
                : '',
        }));
    }, [formValues?.statement?.currencyIso, formValues?.statement?.totalAmount, formValues.totalAmount]);

    useEffect(() => {
        setDifference(prevValues => ({
            ...prevValues,
            roomNights: formValues.totalRoomNights
                ? Math.abs(formValues.totalRoomNights - (formValues?.statement?.totalRoomNights || 0))?.toString()
                : '',
        }));
    }, [formValues?.statement?.totalRoomNights, formValues.totalRoomNights]);

    const { data: invoiceData, isLoading: isLoadingInvoice } = useInvoice(parseInt(id, 10));

    useEffect(() => {
        if (!invoiceData || isLoadingInvoice) {
            return;
        }

        if (!isEditDone.current && id) {
            setFormValues({
                ...(invoiceData as unknown as FormFields),
                dueDate: moment(invoiceData.dueDateLocal),
                invoiceDate: moment(invoiceData.invoiceDateLocal),
                statement: {
                    ...invoiceData.statement,
                    currencyIso: invoiceData.currencyIso,
                },
            });

            setBookingDetails({
                ...bookingDetails,
                hotelName: invoiceData.hotelName,
                relationName: invoiceData.customerName,
                statement: invoiceData.statement,
            });

            setFilesForEdit(invoiceData.files);

            isEditDone.current = true;
        }
    }, [invoiceData, isLoadingInvoice, id, bookingDetails]);

    const { hotels, isLoading: isLoadingHotels } = useHotels(searchHotelTerm);

    const {
        data: relationNames = [],
        isFetching,
        isLoading: isLoadingRelations,
    } = useRelationNames(searchRelationNameTerm, hotel?.id, 100);

    const {
        data: { data: results },
        isLoading,
        isRefetching,
    } = useStatements({
        search: searchStatementTerm,
        customerId: formValues.relationName?.id,
        ...(hotel?.id && { hotelId: hotel.id }),
    });

    const { mutate: downloadStatementMutation } = useMutation(downloadStatement);
    const { mutate: deleteFileFromInvoiceMutation } = useMutation(deleteFileFromInvoice);
    const { mutate: downloadInvoiceFileMutation } = useMutation(downloadInvoiceFile);

    const { mutate: createInvoiceMutation, isLoading: isLoadingCreate } = useMutation(createInvoice, {
        onSuccess: () => {
            showNotification('Inovice successfully uploaded', Severity.Info);
            navigate(INVOICES);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || 'Something went wrong', Severity.Error);
        },
    });

    const { mutate: updateInvoiceMutation, isLoading: isLoadingUpdate } = useMutation(updateInvoice, {
        onSuccess: () => {
            showNotification('Inovice successfully uploaded', Severity.Info);
            navigate(INVOICES);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || 'Something went wrong', Severity.Error);
        },
    });

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;

        if (selectedFiles) {
            setFiles(prevFiles => [...prevFiles, ...Array.from(selectedFiles)]);
        }

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            files: null,
        }));
    };

    const handleRemoveFile = (fileName: string) => {
        setFiles(prevFiles => prevFiles.filter((file, index) => file.name + index !== fileName));
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [name]: null,
        }));
    };

    const handleAutocompleteChange = <T extends keyof FormFields>(name: T, value: Entity | Statement | null) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [name]: null,
        }));
    };

    const handleDateChange = (date: Moment | null, fieldName: 'invoiceDate' | 'dueDate') => {
        setFormValues(prevValues => ({
            ...prevValues,
            [fieldName]: date,
        }));

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [fieldName]: null,
        }));
    };

    const handlePickerShow = (name: 'invoiceDate' | 'dueDate') => {
        setIsOpenPicker(prevValues => ({
            ...prevValues,
            [name]: !prevValues[name],
        }));
    };

    const handleDownloadStatement = () => {
        if (formValues?.statement) {
            downloadStatementMutation(formValues?.statement?.id);
        }
    };

    const handleDeleteFile = (fileId: number) => {
        setFilesForEdit(prevFiles => prevFiles?.filter(file => file.id !== fileId));
        setFilesToDelete([...filesToDelete, fileId]);
    };

    const handleShowFile = (fileId: number) => {
        downloadInvoiceFileMutation({ id: parseInt(id, 10), fileId });
    };

    const validateFields = (): boolean => {
        const validatedReference = isFilledString(formValues.invoiceReference, InputError.Required);

        const validatedDate = isNotNull(formValues.invoiceDate, InputError.Required);
        const validatedDueDate = isNotNull(formValues.dueDate, InputError.Required);

        const validatedAmount = isFilledString(formValues.totalAmount?.toString() || '', InputError.Required);

        const validatedRoomNights = isNotNull(formValues.totalRoomNights, InputError.Required);

        const validatedFiles = id
            ? or(isFilledArray(files, InputError.Required), () => isFilledArray(filesForEdit ?? [], InputError.Required))
            : isFilledArray(files, InputError.Required);

        const fieldErrors: FormErrors = {
            invoiceReference: validatedReference.isValid ? null : validatedReference.error,
            invoiceDate: validatedDate.isValid ? null : validatedDate.error,
            dueDate: validatedDueDate.isValid ? null : validatedDueDate.error,
            totalAmount: validatedAmount.isValid ? null : validatedAmount.error,
            totalRoomNights: validatedRoomNights.isValid ? null : validatedRoomNights.error,
            files: validatedFiles.isValid ? null : validatedFiles.error,
        };

        const isValid = Object.values(fieldErrors).every(error => error === null);

        if (!isValid) {
            setFormErrors(fieldErrors);
        }

        return isValid;
    };

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        const variables = {
            financeStatementId: formValues.statement?.id || 0,
            invoiceReference: formValues.invoiceReference,
            invoiceDateLocal: moment(formValues.invoiceDate).format(DATE_FORMATS['YYYY-MM-DD']),
            dueDateLocal: moment(formValues.dueDate).format(DATE_FORMATS['YYYY-MM-DD']),
            totalAmount: formValues.totalAmount || 0,
            totalRoomNights: formValues.totalRoomNights || 0,
            comments: formValues.comments || '',
            files,
            ...(id && { id }),
        };

        filesToDelete.map(fileId => deleteFileFromInvoiceMutation({ id: parseInt(id, 10), fileId }));

        if (id) {
            updateInvoiceMutation(variables);
        } else {
            createInvoiceMutation(variables);
        }
    };

    const handleBack = () => navigate(-1);

    if ((!invoiceData || isLoading) && id) {
        return <InvoiceLoadingSkeleton />;
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography
                                    variant="h3"
                                    component="h4"
                                    className={clsx(classes.heading, {
                                        [classes.helperText]: formErrors.files !== null,
                                    })}
                                >
                                    Select file(s) *
                                </Typography>
                                {filesForEdit?.map((file, index) => (
                                    <Grid
                                        container
                                        key={index}
                                        sx={{
                                            alignItems: 'center',
                                            marginBottom: '1.5rem',
                                        }}
                                    >
                                        <Grid item xs className={classes.fileContainer} onClick={() => handleShowFile(file.id)}>
                                            <Typography fontSize={'.7rem'} color={COLORS.SLATE_GREY}>
                                                File
                                            </Typography>
                                            <Typography className={classes.fileName}>{file.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={() => handleDeleteFile(file.id)}>
                                                <DeleteOutline fontSize="small" sx={{ color: COLORS.BLUE_DARK }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                {files.map((file, index) => (
                                    <Grid
                                        container
                                        key={index}
                                        sx={{
                                            alignItems: 'center',
                                            marginBottom: '1.5rem',
                                        }}
                                    >
                                        <Grid item xs className={classes.fileContainer}>
                                            <Typography fontSize={'.7rem'} color={COLORS.SLATE_GREY}>
                                                File
                                            </Typography>
                                            <Typography className={classes.fileName}>{file.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={() => handleRemoveFile(file.name + index)}>
                                                <DeleteOutline fontSize="small" sx={{ color: COLORS.BLUE_DARK }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Typography
                                    marginBottom="1rem"
                                    sx={{
                                        color: formErrors.files !== null ? COLORS.RED : '',
                                    }}
                                >
                                    * Minimum 1 file required
                                </Typography>
                                <Button variant="outlined" component="label">
                                    Select file
                                    <input
                                        type="file"
                                        accept=".pdf,.xls,.xlsx"
                                        multiple
                                        hidden
                                        onChange={handleFileChange}
                                        value={''}
                                    />
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card variant="outlined">
                            {!id ? (
                                <CardContent>
                                    <Typography variant="h3" component="h4" className={classes.heading}>
                                        Select bookings on invoice
                                    </Typography>
                                    {user?.isBackoffice && (
                                        <Autocomplete
                                            label="Hotel"
                                            options={(hotels as unknown as Entity[]) || []}
                                            getOptionLabel={(option: Entity) => option.name}
                                            renderOption={(prop, option: Entity) => {
                                                return (
                                                    <Box component="li" {...prop}>
                                                        {option.name}
                                                    </Box>
                                                );
                                            }}
                                            value={hotel}
                                            onChange={(_, newValue: Entity | null) => {
                                                setHotel(newValue);
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setSearchHotelTerm(newInputValue);
                                            }}
                                            loading={isLoadingHotels}
                                            sx={{ marginBottom: '2rem' }}
                                        />
                                    )}
                                    <Autocomplete
                                        label="Relation name"
                                        options={relationNames || []}
                                        getOptionLabel={(option: Entity) => option.name}
                                        renderOption={(prop, option: Entity) => {
                                            return (
                                                <Box component="li" {...prop}>
                                                    {option.name}
                                                </Box>
                                            );
                                        }}
                                        value={formValues.relationName}
                                        onChange={(_, newValue: Entity | null) => {
                                            handleAutocompleteChange('relationName', newValue);
                                        }}
                                        onInputChange={(_, newInputValue) => {
                                            setSearchRelationNameTerm(newInputValue);
                                        }}
                                        loading={isLoadingRelations || isFetching}
                                        sx={{ marginBottom: '2rem' }}
                                        disabled={!hotel}
                                    />
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginBottom: '2rem',
                                        }}
                                    >
                                        <Autocomplete
                                            filterOptions={options => options}
                                            label="Statement period"
                                            options={results ?? []}
                                            getOptionLabel={(option: Statement) => getFormattedDatePeriod(option) ?? ''}
                                            renderOption={(prop, option: Statement) => {
                                                return (
                                                    <Box component="li" {...prop}>
                                                        <Typography>{getFormattedDatePeriod(option)}</Typography>
                                                    </Box>
                                                );
                                            }}
                                            value={formValues.statement}
                                            onChange={(_, newValue) => {
                                                handleAutocompleteChange('statement', newValue);
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setSearchStatementTerm(newInputValue);
                                            }}
                                            loading={isLoading || isRefetching}
                                            sx={{ width: '100%' }}
                                            disabled={!hotel}
                                        />
                                        <IconButton
                                            href=""
                                            target="_blank"
                                            disabled={formValues.statement === null}
                                            onClick={() => handleDownloadStatement()}
                                            sx={{ marginLeft: '1rem' }}
                                        >
                                            <DownloadIcon fontSize="small" style={{ color: COLORS.BLUE_DARK }} />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            ) : (
                                <CardContent>
                                    <BookingsDetails
                                        hotelName={bookingDetails?.hotelName || ''}
                                        relationName={bookingDetails?.relationName || ''}
                                        statementPeriod={bookingDetails?.statement || ({} as Statement)}
                                        handleDownloadStatement={handleDownloadStatement}
                                    />
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography
                            variant="h3"
                            component="h4"
                            className={classes.heading}
                            sx={{ marginBottom: '.5rem !important' }}
                        >
                            Invoice details
                        </Typography>
                        {formValues.statement === null && (
                            <Typography marginBottom="1rem">* First select statement period</Typography>
                        )}
                        <TextField
                            label="Invoice reference"
                            name="invoiceReference"
                            value={formValues.invoiceReference}
                            onChange={handleInputChange}
                            autoComplete="off"
                            required
                            error={formErrors.invoiceReference !== null}
                            helperText={getHelperText(formErrors.invoiceReference, t)}
                            className={formErrors.invoiceReference !== null ? '' : classes.formField}
                            disabled={formValues.statement === null}
                        />
                        <DatePicker
                            label="Invoice date"
                            value={formValues.invoiceDate}
                            onChange={date => handleDateChange(date, 'invoiceDate')}
                            format={DATE_FORMATS['DD MMM YYYY']}
                            slotProps={{
                                textField: {
                                    variant: 'filled',
                                    required: true,
                                    onClick: () => handlePickerShow('invoiceDate'),
                                    error: formErrors.invoiceDate !== null,
                                    helperText: getHelperText(formErrors.invoiceDate, t),
                                },
                            }}
                            open={isOpenPicker.invoiceDate}
                            onClose={() => handlePickerShow('invoiceDate')}
                            className={formErrors.invoiceDate !== null ? '' : classes.formField}
                            sx={{ width: '100%' }}
                            disabled={formValues.statement === null}
                        />
                        <DatePicker
                            label="Due date"
                            value={formValues.dueDate}
                            onChange={date => handleDateChange(date, 'dueDate')}
                            format={DATE_FORMATS['DD MMM YYYY']}
                            slotProps={{
                                textField: {
                                    variant: 'filled',
                                    required: true,
                                    onClick: () => handlePickerShow('dueDate'),
                                    error: formErrors.dueDate !== null,
                                    helperText: getHelperText(formErrors.dueDate, t),
                                },
                            }}
                            open={isOpenPicker.dueDate}
                            onClose={() => handlePickerShow('dueDate')}
                            className={formErrors.dueDate !== null ? '' : classes.formField}
                            sx={{ width: '100%' }}
                            disabled={formValues.statement === null}
                        />
                        <TextField
                            label="Comments (optional)"
                            name="comments"
                            value={formValues.comments}
                            onChange={handleInputChange}
                            InputProps={{
                                minRows: '3',
                                multiline: true,
                            }}
                            placeholder="Add any comments here"
                            disabled={formValues.statement === null}
                        />
                        <Typography variant="h3" component="h4" className={classes.heading}>
                            Amount
                            <InfoOutlined className={classes.icon} />
                        </Typography>
                        <TextField
                            label="Invoice total amount"
                            name="totalAmount"
                            value={formValues.totalAmount}
                            onChange={handleInputChange}
                            autoComplete="off"
                            required
                            error={formErrors.totalAmount !== null}
                            helperText={getHelperText(formErrors.totalAmount, t)}
                            className={formErrors.totalAmount !== null ? '' : classes.formField}
                            disabled={formValues.statement === null}
                            type="number"
                        />
                        <Grid item xs={12} display={'flex'} flexDirection={'row'} rowGap={'1rem'} marginBottom={'1rem'}>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label={'Statement period'}
                                    value={`${formValues.statement?.totalAmount?.toLocaleString() || ''} 
                  ${formValues.statement?.currencyIso || ''}`}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label={'Statement period difference'}
                                    value={difference.amount || ''}
                                    iconElement={getDifferenceIcon(
                                        formValues?.totalAmount || undefined,
                                        formValues.statement?.totalAmount
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Typography variant="h3" component="h4" className={classes.heading}>
                            Room nights
                            <InfoOutlined className={classes.icon} />
                        </Typography>
                        <TextField
                            label="Invoice total room nights"
                            name="totalRoomNights"
                            value={formValues.totalRoomNights}
                            onChange={handleInputChange}
                            autoComplete="off"
                            required
                            error={formErrors.totalRoomNights !== null}
                            helperText={getHelperText(formErrors.totalRoomNights, t)}
                            className={formErrors.totalRoomNights !== null ? '' : classes.formField}
                            type="number"
                            disabled={formValues.statement === null}
                        />
                        <Grid item xs={12} display={'flex'} flexDirection={'row'} rowGap={'1rem'}>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label={'Statement period total'}
                                    value={formValues.statement?.totalRoomNights?.toString() || ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LabeledValue
                                    label={'Statement period difference'}
                                    value={difference.roomNights || ''}
                                    iconElement={getDifferenceRoomIcon(
                                        formValues?.totalRoomNights || 0,
                                        formValues.statement?.totalRoomNights
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <PrimaryButton onClick={handleSubmit} className={classes.mr1} loading={isLoadingCreate || isLoadingUpdate}>
                    {id ? 'Update' : 'Upload'}
                </PrimaryButton>
                <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default UploadInvoice;
