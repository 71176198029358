import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import moment from 'moment';
import { useMemo } from 'react';

import { COLORS } from '../../../constans/colors';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import useDataGridStyles from '../../../styles/DataGrid';

export const useStatementsColumns = (handleDownloadStatement: (id: number) => void) => {
    const dataGridClases = useDataGridStyles();

    return useMemo(
        () => [
            {
                field: 'dateRange',
                headerName: 'Date range',
                minWidth: 250,
                renderCell: (params: GridRowParams) => {
                    const isSameDate = params.row.startDateLocal === params.row.endDateLocal;

                    const formattedStartDate = moment(params.row.startDateLocal).format(DATE_FORMATS['DD MMM YYYY']);

                    const formattedEndDate = moment(params.row.endDateLocal).format(DATE_FORMATS['DD MMM YYYY']);

                    return (
                        <Box>
                            {isSameDate && (
                                <Typography sx={{ color: COLORS.SLATE_GREY, fontSize: '12px' }}>
                                    {params.row.bookingNumber}
                                </Typography>
                            )}
                            <Typography>
                                {isSameDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`}
                            </Typography>
                        </Box>
                    );
                },
            },

            {
                field: 'customerName',
                headerName: 'Hotel’s account name',
                width: 200,
            },
            {
                field: 'hotelName',
                headerName: 'Customer’s account name',
                width: 200,
            },
            {
                field: 'totalAmount',
                headerName: 'Statement Amount',
                minWidth: 200,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    return (
                        <>
                            {params.row.currencyIso} {params.row.totalAmount}
                        </>
                    );
                },
            },
            { field: 'totalRoomNights', headerName: 'Statement room nights', width: 180 },
            {
                field: 'download',
                headerName: 'Download statement',
                minWidth: 200,
                flex: 1,
                headerClassName: dataGridClases.hideSeparator,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderCell: (params: any) => (
                    <IconButton
                        href={params.value}
                        target="_blank"
                        onClick={event => {
                            event.stopPropagation();
                            handleDownloadStatement(params.row.id);
                        }}
                    >
                        <DownloadIcon fontSize="small" style={{ color: COLORS.BLUE_DARK }} />
                    </IconButton>
                ),
                sortable: false,
                filterable: false,
            },
        ],
        [dataGridClases.hideSeparator, handleDownloadStatement]
    );
};
