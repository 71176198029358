import { AxiosError, AxiosResponse } from 'axios';
import { Booking, ConfirmBooking } from '../pages/reservations/api/types';
import apiClient from './api';
import { HOTEL_CONFIRM_BOOKING } from './routes';

export const confirm = async (variables: ConfirmBooking): Promise<AxiosResponse<Booking[]> | undefined> => {
    const { payload } = variables;

    try {
        const response = await apiClient.patch<Booking[]>(`${HOTEL_CONFIRM_BOOKING}/`, payload);

        return response;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            throw Error(error.response?.data.message);
        }
    }
};
