import { Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../../../constans/colors';
import { REQUEST_ID_PREFIX } from '../../../../constans/prefixes';
import { getCheckDate, getDifferenceInDays } from '../../../../helpers/dateUtils';
import StatusButton from '../StatusButton';

const useStyles = makeStyles({
    card: {
        border: `1px solid ${COLORS.DARK_GRAY}`,
        margin: '.75rem 0 .75rem 0',
        padding: '2rem',
    },
    heading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headingMobile: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '1rem',
    },
    primaryHeading: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '.5rem',
    },
    secondaryText: {
        color: COLORS.DARK_GRAY,
    },
    headingDetails: {
        display: 'flex',
        alignItems: 'center',
    },
    headingButton: {
        marginLeft: '1rem',
    },
});

interface BookingHeadingProps {
    requestAddress?: string;
    requestId: number;
    status: string;
    checkInDate: string;
    checkOutDate: string;
}

const RequestHeading = ({ requestAddress, requestId, status, checkInDate, checkOutDate }: BookingHeadingProps) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            <div className={isMobile ? classes.headingMobile : classes.heading}>
                <Typography variant="h6" className={classes.primaryHeading}>
                    {getCheckDate(checkInDate)} - {getCheckDate(checkOutDate)}
                    <span className={classes.secondaryText}>&nbsp;({getDifferenceInDays(checkOutDate, checkInDate)} nights)</span>
                </Typography>
                <div className={classes.headingDetails}>
                    <Typography variant="body1" className={classes.secondaryText}>
                        {REQUEST_ID_PREFIX}
                        {requestId}
                    </Typography>
                    <StatusButton className={classes.headingButton} variant={status}>
                        {status}
                    </StatusButton>
                </div>
            </div>
            <Typography variant="body1" className={classes.secondaryText}>
                Nearby: {requestAddress}
            </Typography>
        </>
    );
};

export default RequestHeading;
