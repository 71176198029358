import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import ErrorPage from '../../components/ErrorPage';
import { REQUESTS_PENDING } from '../../constans/urlPaths';
import { tooManyRequestsIcon } from '../../images';

const ErrorTooManyRequestPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(REQUESTS_PENDING);
        }, 10000);

        return () => clearTimeout(timer);
    });

    return (
        <ErrorPage
            icon={tooManyRequestsIcon}
            header={'We are receiving too many requests'}
            description={
                'The platform is experiencing a large amount of requests from this account. Please wait 30 seconds or try again later.'
            }
            isButtonVisable={false}
        />
    );
};

export default ErrorTooManyRequestPage;
