import { Typography } from '@mui/material';

import SimpleLink from '../../components/SimpleLink';
import { LOCAL_STORAGE_KEYS } from '../../constans/localStorageKeys';
import { BREADCRUMB_DIVIDER } from '../../constans/placeholders';
import {
    ACCOUNTS_CUSTOMERS,
    ACCOUNTS_HOTELS,
    ACCOUNT_PEOPLE_ACTIVE,
    ACCOUNT_PEOPLE_DEACTIVATED,
    ACCOUNT_PEOPLE_INVITED,
    EDIT_HOTEL_USER,
    EDIT_PEOPLE,
    HOTEL_USERS_ACTIVE,
    HOTEL_USERS_DEACTIVATED,
    HOTEL_USERS_INVITED,
    PEOPLE_ACTIVE,
    PEOPLE_DEACTIVATED,
    PEOPLE_INVITED,
} from '../../constans/urlPaths';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import { SCOPES } from '../../permissions/permissionMaps';
import PermissionsGate from '../../permissions/PermissionsGate';
import EditPeople from './editPeople/EditPeople';
import HotelUserContent from './hotelUserContent/HotelUserContent';
import PeopleContent from './peopleContent/PeopleContent';

export enum PeopleTabs {
    Active,
    Invited,
    Deactivated,
}

export const activePeople: PageDefinition = {
    path: PEOPLE_ACTIVE,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Users" link={PEOPLE_ACTIVE} />
                                <Typography component="span">{BREADCRUMB_DIVIDER}Active</Typography>
                            </>
                        ),
                    }}
                >
                    <PeopleContent tab={PeopleTabs.Active} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const invitedPeople: PageDefinition = {
    path: PEOPLE_INVITED,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Users" link={PEOPLE_ACTIVE} />
                                <Typography component="span">{BREADCRUMB_DIVIDER}Invited</Typography>
                            </>
                        ),
                    }}
                >
                    <PeopleContent tab={PeopleTabs.Invited} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const deactivatedPeople: PageDefinition = {
    path: PEOPLE_DEACTIVATED,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Users" link={PEOPLE_ACTIVE} />
                                <Typography component="span">{BREADCRUMB_DIVIDER}Deactivated</Typography>
                            </>
                        ),
                    }}
                >
                    <PeopleContent tab={PeopleTabs.Deactivated} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const editPeople: PageDefinition = {
    path: EDIT_PEOPLE,
    Component() {
        const userName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EDITED) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Users" link={PEOPLE_ACTIVE} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}
                                    {userName}
                                    {BREADCRUMB_DIVIDER}Edit user
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <EditPeople />
                </Page>
            </PermissionsGate>
        );
    },
};

export const accountActivePeople: PageDefinition = {
    path: ACCOUNT_PEOPLE_ACTIVE,
    Component() {
        const accountName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_CUSTOMERS} />
                                {accountName && <Typography component="span">{` / ${accountName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <PeopleContent tab={PeopleTabs.Active} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const accountInvitedPeople: PageDefinition = {
    path: ACCOUNT_PEOPLE_INVITED,
    Component() {
        const accountName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_CUSTOMERS} />
                                {accountName && <Typography component="span">{` / ${accountName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <PeopleContent tab={PeopleTabs.Invited} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const accountDeactivatedPeople: PageDefinition = {
    path: ACCOUNT_PEOPLE_DEACTIVATED,
    Component() {
        const accountName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCOUNT_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Accounts" link={ACCOUNTS_CUSTOMERS} />
                                {accountName && <Typography component="span">{` / ${accountName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <PeopleContent tab={PeopleTabs.Deactivated} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const hotelActiveUsers: PageDefinition = {
    path: `${HOTEL_USERS_ACTIVE}/:id`,
    Component() {
        const hotelName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.HOTEL_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Hotels" link={ACCOUNTS_HOTELS} />
                                {hotelName && <Typography component="span">{` / ${hotelName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <HotelUserContent tab={PeopleTabs.Active} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const ownHotelActiveUsers: PageDefinition = {
    path: `${HOTEL_USERS_ACTIVE}`,
    Component() {
        const hotelName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.HOTEL_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Hotels" link={ACCOUNTS_HOTELS} />
                                {hotelName && <Typography component="span">{` / ${hotelName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <HotelUserContent tab={PeopleTabs.Active} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const hotelInvitedUsers: PageDefinition = {
    path: `${HOTEL_USERS_INVITED}/:id`,
    Component() {
        const hotelName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.HOTEL_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Hotels" link={ACCOUNTS_HOTELS} />
                                {hotelName && <Typography component="span">{` / ${hotelName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <HotelUserContent tab={PeopleTabs.Invited} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const ownHotelInvitedUsers: PageDefinition = {
    path: `${HOTEL_USERS_INVITED}`,
    Component() {
        const hotelName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.HOTEL_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Hotels" link={ACCOUNTS_HOTELS} />
                                {hotelName && <Typography component="span">{` / ${hotelName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <HotelUserContent tab={PeopleTabs.Invited} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const hotelDeactivatedUsers: PageDefinition = {
    path: `${HOTEL_USERS_DEACTIVATED}/:id`,
    Component() {
        const hotelName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.HOTEL_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Hotels" link={ACCOUNTS_HOTELS} />
                                {hotelName && <Typography component="span">{` / ${hotelName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <HotelUserContent tab={PeopleTabs.Deactivated} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const ownHotelDeactivatedUsers: PageDefinition = {
    path: `${HOTEL_USERS_DEACTIVATED}`,
    Component() {
        const hotelName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.HOTEL_USERS) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Hotels" link={ACCOUNTS_HOTELS} />
                                {hotelName && <Typography component="span">{` / ${hotelName}`}</Typography>}
                                <Typography component="span"> / Users</Typography>
                            </>
                        ),
                    }}
                >
                    <HotelUserContent tab={PeopleTabs.Deactivated} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const editHotelUser: PageDefinition = {
    path: `${EDIT_HOTEL_USER}:id`,
    Component() {
        const userName = window.localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EDITED) ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Users" link={HOTEL_USERS_ACTIVE} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}
                                    {userName}
                                    {BREADCRUMB_DIVIDER}Edit user
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <EditPeople />
                </Page>
            </PermissionsGate>
        );
    },
};
