import { AddressRequest } from '../../pages/accounts/api/types';

function isAnAddress(obj: object): boolean {
    const objectKeys = Object.keys(obj);

    const requiredKeys = ['full_address', 'id', 'country', 'address_line', 'city', 'state_province', 'postal_code'];

    return requiredKeys.every(index => objectKeys.includes(index));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapAddressToAddressRequest = (newAddress: any): AddressRequest => {
    if (isAnAddress(newAddress)) {
        return {
            city: newAddress.city ?? '',
            state_province: newAddress.state_province,
            postal_code: newAddress.postal_code,
            country_code: newAddress.country.alpha2,
            address_line: newAddress.address_line,
            lat: newAddress.lat,
            long: newAddress.long,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return newAddress;
};
