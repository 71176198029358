import { useQuery } from 'react-query';

import { BOOKING } from '../../../constans/queryKeys';
import { viewBooking } from '../../../services/booking';

export function useBooking(id: number) {
    const { data, isLoading, isError, isFetching, isFetched } = useQuery([BOOKING], () => viewBooking(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return { data, isLoading, isError, isFetching, isFetched };
}
