import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import moment from 'moment';

import { COLORS } from '../../../../constans/colors';
import { DATE_FORMATS } from '../../../../helpers/dateFormats';
import { Statement } from '../../../billing/api/types';

export const getFormattedDatePeriod = (option: Statement) => {
    const isSameDate = option.startDateLocal === option.endDateLocal;

    const formattedStartDate = moment(option.startDateLocal).format(DATE_FORMATS['DD MMM YYYY']);

    const formattedEndDate = moment(option.endDateLocal).format(DATE_FORMATS['DD MMM YYYY']);

    const formattedDate = isSameDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`;

    const bookingNumber = isSameDate ? `(${option?.bookingNumber})` : '';

    return `${formattedDate} ${bookingNumber}`;
};

export const getDifferenceIcon = (invoice?: number, statement?: number) => {
    if (invoice && statement) {
        const difference = invoice - statement;

        if (difference <= 0) {
            return <CloseOutlinedIcon fontSize="small" sx={{ color: COLORS.RED }} />;
        }

        return <CheckCircleOutlinedIcon fontSize="small" sx={{ color: COLORS.GREEN }} />;
    }
};

export const getDifferenceRoomIcon = (invoice?: number, roomTotal?: number) => {
    if (invoice && roomTotal) {
        if (invoice !== roomTotal) {
            return <CloseOutlinedIcon fontSize="small" sx={{ color: COLORS.RED }} />;
        }

        return <CheckCircleOutlinedIcon fontSize="small" sx={{ color: COLORS.GREEN }} />;
    }
};
