import { Form, PrimaryButton, SignedOutLayout, TextField } from '@get-e/react-components';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ShowPasswordButton from '../../../components/ShowPasswordButton';
import { SIGN_IN } from '../../../constans/urlPaths';
import { useNotificationContext, Severity } from '../../../context/NotificationContext';
import useUrlQuery from '../../../helpers/useUrlQuery';
import getHelperText from '../../../helpers/validation/getHelperText';
import getInputError from '../../../helpers/validation/getInputError';
import InputError from '../../../helpers/validation/InputError';
import isPasswordStrong from '../../../helpers/validation/validators/isPasswordStrong';
import { logoIcon } from '../../../images';
import { acceptUserInvite } from '../../../services/users';

const TOKEN_QUERY_PARAMETER = 'token';

const useStyles = makeStyles({
    container: {
        padding: '2em',
        maxWidth: '430px',
        margin: '0 auto',
    },
    heading: { marginBottom: '1rem', marginTop: '2rem' },
    signInButtonContainer: { marginTop: '1rem' },
    formField: {
        marginBottom: '2rem',
    },
    logoContainer: {
        display: 'block',
        margin: '0 auto',
        maxWidth: 110,
    },
    helpText: {
        fontSize: '12px',
    },
});

const AcceptInvite = () => {
    const classes = useStyles();
    const autoFocusRef = useRef<HTMLInputElement>();
    const navigate = useNavigate();
    const urlParams = useUrlQuery();
    const { showNotification } = useNotificationContext();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState<InputError | null>(null);

    const [confirmPasswordError, setConfirmPasswordError] = useState<InputError | null>(null);

    const [showingPassword, setShowingPassword] = useState(false);
    const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);
    const { t } = useTranslation();

    const token = urlParams.get(TOKEN_QUERY_PARAMETER) ?? '';

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const { mutate: acceptUserInviteMutation, isLoading } = useMutation(acceptUserInvite, {
        onSuccess: () => {
            navigate(SIGN_IN);
        },
        onError: (error: AxiosError<Error>) => {
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    function submitForm() {
        const validated = isPasswordStrong(password, InputError.NotStrongPassword);

        if (!validated.isValid) {
            setPasswordError(getInputError(validated));
            return;
        }

        if (password.trim() !== confirmPassword.trim()) {
            setConfirmPasswordError(InputError.NoMatch);
            return;
        }

        if (firstName !== '' && lastName !== '' && password !== '' && confirmPassword !== '' && token !== '') {
            acceptUserInviteMutation({
                firstName,
                lastName,
                password,
                confirmPassword,
                token,
            });
        }
    }

    return (
        <SignedOutLayout>
            <Paper elevation={0} className={classes.container}>
                <img src={logoIcon} alt={'Logo'} className={classes.logoContainer} />
                <Form onSubmit={submitForm}>
                    <Typography variant="h2" component="h2" className={classes.heading}>
                        Welcome!
                    </Typography>
                    <Typography variant="body1" mb={'2em'}>
                        Complete details below to activate your profile on the Hotel app by Get-e.
                    </Typography>
                    <TextField
                        value={firstName}
                        onChange={event => {
                            setFirstName(event.target.value);
                        }}
                        label="First name"
                        type="text"
                        autoFocus
                        autoComplete="firstName"
                        name="firstName"
                        inputRef={autoFocusRef}
                        required
                        className={classes.formField}
                        noHelperTextSpace
                    />
                    <TextField
                        value={lastName}
                        onChange={event => {
                            setLastName(event.target.value);
                        }}
                        label="Last name"
                        type="text"
                        autoFocus
                        autoComplete="lastName"
                        name="lastName"
                        inputRef={autoFocusRef}
                        required
                        className={classes.formField}
                        noHelperTextSpace
                    />
                    <TextField
                        label="Password"
                        type={showingPassword ? 'text' : 'password'}
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                            setPasswordError(null);
                        }}
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <ShowPasswordButton
                                    // eslint-disable-next-line @typescript-eslint/no-shadow
                                    onShowPassword={password => {
                                        setShowingPassword(password);
                                    }}
                                />
                            ),
                        }}
                        error={passwordError !== null}
                        helperText={getHelperText(passwordError, t)}
                        required
                        className={classes.formField}
                    />

                    <TextField
                        label="Confirm password"
                        type={showingConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={event => {
                            setConfirmPassword(event.target.value);
                            setConfirmPasswordError(null);
                        }}
                        autoComplete="confirm-password"
                        InputProps={{
                            endAdornment: (
                                <ShowPasswordButton
                                    // eslint-disable-next-line @typescript-eslint/no-shadow
                                    onShowPassword={password => {
                                        setShowingConfirmPassword(password);
                                    }}
                                />
                            ),
                        }}
                        error={confirmPasswordError !== null}
                        helperText={getHelperText(confirmPasswordError, t)}
                        required
                        className={classes.formField}
                    />
                    <input name="token" type="hidden" value={token} />
                    <Typography variant="body1" mb={'2em'} className={classes.helpText}>
                        Use 8 or more characters with a mix of letters, numbers & symbols
                    </Typography>
                    <div className={classes.signInButtonContainer}>
                        <PrimaryButton onClick={submitForm} submitsForm loading={isLoading}>
                            Activate profile
                        </PrimaryButton>
                    </div>
                </Form>
            </Paper>
        </SignedOutLayout>
    );
};

export default AcceptInvite;
