import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FunctionComponent } from 'react';
import { COLORS } from '../constans/colors';

const useStyles = makeStyles(() => ({
    wrapper: {
        marginBottom: '0.75rem',
    },
    label: {
        fontSize: '1rem',
        marginRight: '1rem',
        fontWeight: '700',
    },
    data: {
        fontSize: '1rem',
        color: COLORS.BLACK,
    },
    dataSuffix: {
        fontSize: '1rem',
        color: COLORS.DARK_GRAY,
        marginLeft: '1rem',
    },
}));

interface LabeledDataProps {
    label: string;
    data?: string;
    dataSuffix?: string;
    labelColor?: string;
    labelColumnNumber?: number;
    dataColumnNumber?: number;
}

const LabeledData: FunctionComponent<LabeledDataProps> = ({
    label = '',
    data = '',
    dataSuffix = '',
    labelColor = COLORS.BLUE,
    labelColumnNumber = 4,
    dataColumnNumber = 8,
}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2} className={classes.wrapper}>
            <Grid item xs={labelColumnNumber}>
                <Typography className={classes.label} sx={{ color: labelColor }} variant="caption" gutterBottom>
                    {label}
                </Typography>
            </Grid>
            {data && (
                <Grid item xs={dataColumnNumber}>
                    <Typography className={classes.data} variant="caption" gutterBottom>
                        {data}
                    </Typography>
                    {dataSuffix && (
                        <Typography className={classes.dataSuffix} variant="caption">
                            {dataSuffix}
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default LabeledData;
