import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ALL_CUSTOMERS } from '../../../constans/queryKeys';
import { listCustomers } from '../../../services/customer';
import { Customer, CustomerResponse } from './types';

export function useCustomers(search?: string, isEnabled = true) {
    const { data, isLoading, isError, refetch } = useQuery([ALL_CUSTOMERS + search], () => listCustomers(search), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: isEnabled,
    });

    const mapCustomer = (customer: CustomerResponse): Customer => ({
        id: customer.id,
        name: customer.name,
        address: customer.address?.full_address,
    });

    return useMemo(() => {
        const accounts = data?.map((customer: CustomerResponse) => mapCustomer(customer)) ?? [];

        return { accounts, isLoading, isError, refetch };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError]);
}
