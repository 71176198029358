import { PrimaryButton, Spinner, TextField } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment, StandardTextFieldProps, FormControl, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { DateRange, DateRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { COLORS } from '../../constans/colors';
import { EDIT_INVOICE_BASE_URL, UPLOAD_INVOICE } from '../../constans/urlPaths';
import { useAuth, useIsBackoffice } from '../../context/AuthenticatedUserContext';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import { DATE_FORMATS } from '../../helpers/dateFormats';
import { dateTimeRangePickerStyles, dayStyles, textFieldStyles } from '../../styles/DateTimeRangePicker';
import { useDataGridStyles } from '../accounts/styles/useDataGridStyles';
import { deleteInvoice } from './api/deleteInvoice';
import downloadFiles from './api/downloadFiles';
import { Invoice } from './api/types';
import { useInvoices } from './api/useInvoices';
import { useInvoicesColumns } from './hooks/useInvoicesColumns';
import { StatusOption } from './types';

let searchTimeoutId: ReturnType<typeof setTimeout> | number = -1;

interface Status {
    matched: boolean;
    noMatch: boolean;
}

const getStatusOption = (status: Status) => {
    if (status.matched && status.noMatch) {
        return '';
    } else if (status.matched) {
        return StatusOption.MATCHED;
    } else if (status.noMatch) {
        return StatusOption.NO_MATCHED;
    }
};

const Invoices = () => {
    const { user } = useAuth();
    const { showNotification } = useNotificationContext();
    const isBackoffice = useIsBackoffice();
    const isHotelUser = Boolean(user?.hotel);
    const isCustomerUser = Boolean(user?.customer);
    const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');
    // const [isDateRangeValid, setIsDateRangeValid] = useState(true);

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Moment>>([null, null]);

    const [page, setPage] = useState(0);
    const dataGridClasses = useDataGridStyles();
    const [searchPhrase, setSearchPhrase] = useState('');
    const navigate = useNavigate();

    const [status, setStatus] = useState<Status>({
        matched: true,
        noMatch: true,
    });

    const {
        data: { data: results, meta },
        isLoading,
        isRefetching,
        refetch,
    } = useInvoices({
        search: debouncedSearchPhrase,
        fromDateLocal: selectedDateRange?.includes(null)
            ? undefined
            : moment(selectedDateRange[0]).format(DATE_FORMATS['YYYY-MM-DD']),
        toDateLocal: selectedDateRange?.includes(null)
            ? undefined
            : moment(selectedDateRange[1]).format(DATE_FORMATS['YYYY-MM-DD']),
        page,
        status: getStatusOption(status) || '',
    });

    const { mutate: downloadFilesMutation } = useMutation(downloadFiles);

    const { mutate: deleteMutation } = useMutation(deleteInvoice, {
        onSuccess: () => {
            showNotification('Invoice successfully withdrawn.', Severity.Info);
            refetch();
        },
        onError: () => {
            showNotification('Something went wrong. Please try it later.', Severity.Error);
        },
    });

    const handleSearchInputOnChange = useCallback(
        (value: string) => {
            setSearchPhrase(value);
            clearTimeout(searchTimeoutId);
            searchTimeoutId = setTimeout(() => {
                setDebouncedSearchPhrase(value);
            }, 600);
        },
        [setDebouncedSearchPhrase]
    );

    const handleDownloadFiles = (id: number) => {
        downloadFilesMutation(id);
    };

    const handleDelete = (id: number) => {
        deleteMutation(id);
    };

    const handleUploadNewInovoice = () => navigate(UPLOAD_INVOICE);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatus({
            ...status,
            [event.target.name]: event.target.checked,
        });
    };

    const handleRowClick: GridEventListener<'rowClick'> = (params, event) => {
        event.preventDefault();
        navigate(`${EDIT_INVOICE_BASE_URL}${params.id}`);
    };

    const columns = useInvoicesColumns(handleDownloadFiles, handleDelete);

    return (
        <Grid container alignItems="flex-start">
            <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                    <Typography
                        sx={{
                            color: COLORS.BLUE,
                            fontSize: '1.5rem',
                            fontWeight: 700,
                        }}
                    >
                        Invoices
                    </Typography>
                </Grid>
                <Grid item>
                    <PrimaryButton onClick={handleUploadNewInovoice} icon={<AddIcon />}>
                        Upload Invoices
                    </PrimaryButton>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Grid item xs={12} md={8} xl={8}>
                    <TextField
                        style={{ marginBottom: 0 }}
                        type="text"
                        autoComplete="off"
                        value={searchPhrase}
                        noHelperTextSpace
                        onChange={event => {
                            handleSearchInputOnChange(event.target.value);
                        }}
                        label="Search by hotel or customer name"
                        name="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading || isRefetching ? <Spinner size={16} /> : <SearchIcon />}
                                </InputAdornment>
                            ),
                            onBlur: event => setSearchPhrase(event.target.value),
                        }}
                        onBlur={event => setSearchPhrase(event.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    xl={4}
                    sx={{
                        paddingLeft: ['0', '0', '2rem'],
                        marginTop: ['1rem', '1rem', 0],
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <DateRangePicker
                        sx={dateTimeRangePickerStyles}
                        label={'Date range'}
                        onChange={newValue => {
                            // const [startDate, endDate] = newValue || [];
                            // if (startDate && endDate) {
                            //     const dayDifference = endDate.startOf('day').diff(startDate.startOf('day'), 'days');
                            //     if (dayDifference >= 1) {
                            //         setSelectedDateRange(newValue);
                            //         setIsDateRangeValid(true);
                            //     } else {
                            //         setIsDateRangeValid(false);
                            //     }
                            // } else {
                            //     setSelectedDateRange(newValue);
                            // }
                            setSelectedDateRange(newValue);
                        }}
                        slotProps={{
                            textField: {
                                ...(textFieldStyles as StandardTextFieldProps),
                                placeholder: 'All time',
                                // helperText: isDateRangeValid ? '' : 'Date range must have one day difference.',
                                // error: !isDateRangeValid
                            },
                            field: { clearable: true },
                            day: {
                                sx: dayStyles,
                            },
                        }}
                        slots={{ field: SingleInputDateTimeRangeField }}
                        format={DATE_FORMATS['DD MMM YYYY']}
                    />
                </Grid>
            </Grid>
            <Grid marginBottom={'.5rem'} display="flex" alignItems="center">
                <Typography variant="body1" style={{ marginRight: '8px' }}>
                    Filter by status:
                </Typography>
                <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={status.matched} onChange={handleCheckboxChange} name="matched" color="primary" />
                        }
                        label="Matched"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={status.noMatch} onChange={handleCheckboxChange} name="noMatch" color="primary" />
                        }
                        label="No match"
                    />
                </FormControl>
            </Grid>
            <Grid container sx={{ height: '400px' }}>
                <DataGrid
                    className={dataGridClasses.dataGrid}
                    disableColumnSelector
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={results ?? []}
                    columns={columns as Array<GridColDef<Invoice[]>>}
                    pagination
                    page={page}
                    pageSize={meta?.per_page}
                    paginationMode="server"
                    onPageChange={newPage => setPage(newPage)}
                    rowCount={meta?.total ?? 0}
                    loading={isLoading || isRefetching}
                    columnVisibilityModel={{
                        hotelName: isCustomerUser || isBackoffice,
                        customerName: isHotelUser || isBackoffice,
                    }}
                    onRowClick={handleRowClick}
                />
            </Grid>
        </Grid>
    );
};

export default Invoices;
