import { Entity, StatementVariables } from '../pages/billing/api/types';
import apiClient from './api';
import { getRelationNamesRoute, STATEMENTS } from './routes';

export const listStatements = async (variables: StatementVariables) => {
    const searchParams = new URLSearchParams({
        searchValue: variables?.search || '',
        customerId: variables?.customerId?.toString() || '',
        hotelId: variables?.hotelId?.toString() || '',
        fromDateLocal: variables?.fromDateLocal || '',
        toDateLocal: variables?.toDateLocal || '',
        limit: variables?.limit?.toString() || '',
        page: ((variables?.page ?? 0) + 1)?.toString() || '',
    }).toString();

    const { data } = await apiClient.get(`${STATEMENTS}?${searchParams}`);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
};

export const listRelationNames = async (search: string, hotelId = 0, limit?: number): Promise<Entity[]> => {
    const searchParams = new URLSearchParams({
        searchValue: search || '',
        limit: limit?.toString() || '',
    }).toString();

    const response = await apiClient.get(`${getRelationNamesRoute(hotelId)}?${searchParams}`);

    return response?.data as Entity[];
};
