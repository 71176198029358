export const PERMISSION_NAMES = {
    request_manager: 'request_manager',
    booking_manager: 'booking_manager',
    customer_role_manager: 'customer_role_manager',
    user_manager: 'user_manager',
    traveller_manager: 'traveller_manager',
};

export const SCOPES = {
    canCreateRequest: 'can-create-request',
    canAccessRequestsList: 'can-access-requests',
    canEditRequest: 'can-edit-request',
    canCancelRequest: 'can-cancel-request',
    canViewRequest: 'can-view-request',
    canAccessUsers: 'can-access-users',
    canCreateUserInvite: 'can-create-user-invite',
    canAddTraveller: 'can-add-traveller',

    canAccessBookingsList: 'can-access-bookings',
    canCreateBooking: 'can-create-booking',
    canEditBooking: 'can-edit-booking',
    canViewBooking: 'can-view-booking',

    canAccessFinancial: 'can-financial',
};

export const PERMISSIONS = {
    [PERMISSION_NAMES.request_manager]: [
        SCOPES.canCreateRequest,
        SCOPES.canAccessRequestsList,
        SCOPES.canEditRequest,
        SCOPES.canCancelRequest,
        SCOPES.canViewRequest,
    ],
    [PERMISSION_NAMES.booking_manager]: [
        SCOPES.canAccessBookingsList,
        SCOPES.canCreateBooking,
        SCOPES.canEditBooking,
        SCOPES.canViewBooking,
        SCOPES.canAccessFinancial,
    ],
    [PERMISSION_NAMES.customer_role_manager]: [SCOPES.canViewRequest, SCOPES.canAccessFinancial],
    [PERMISSION_NAMES.user_manager]: [SCOPES.canAccessUsers, SCOPES.canCreateUserInvite],
    [PERMISSION_NAMES.traveller_manager]: [SCOPES.canAddTraveller],
};
