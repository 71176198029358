import { FullScreenLoader, PrimaryButton } from '@get-e/react-components';
import { Pagination } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { REQUESTS, CREATE_REQUEST } from '../../../../constans/urlPaths';
import { emptyAirline } from '../../../../images';
import { Request } from '../../api/types';
import RequestCard from '../../components/requestCard/RequestCard';

const useStyles = makeStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    emptyDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
        marginBottom: '5rem',
    },
    createButton: {
        display: 'flex',
        margin: '0 auto',
    },
});

interface RequestsProps {
    res?: Request[];
    setPage: (num: number) => void;
    countPages?: number;
    isLoadingBookings: boolean;
    isRefetchingBookings: boolean;
}

const List = ({ res, setPage, countPages, isLoadingBookings, isRefetchingBookings }: RequestsProps) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleRequestClick = (id: number) => {
        navigate(`${REQUESTS}${id}`, {
            state: {
                id,
            },
        });
    };

    return (
        <>
            {(isLoadingBookings || isRefetchingBookings) && <FullScreenLoader />}
            {res?.map((request: Request) => (
                <RequestCard
                    key={uuid()}
                    requestAddress={request?.request_address?.short_name}
                    requestId={request?.id}
                    status={request?.status}
                    checkInDate={request?.check_in_date_local}
                    checkOutDate={request?.check_out_date_local}
                    bookings={request?.bookings}
                    guests={request?.guests}
                    onClick={handleRequestClick}
                    customerName={request?.customer_name}
                />
            ))}
            {res?.length === 0 ? (
                <>
                    <div className={classes.emptyDiv}>
                        <img src={emptyAirline} alt="fireSpot" />
                    </div>
                    <PrimaryButton
                        onClick={() => {
                            navigate(CREATE_REQUEST);
                        }}
                        className={classes.createButton}
                    >
                        New Request
                    </PrimaryButton>
                </>
            ) : (
                <Stack spacing={2}>
                    <Pagination
                        count={countPages}
                        color="primary"
                        className={classes.pagination}
                        onChange={(_, pageNumber) => {
                            setPage(pageNumber);
                        }}
                    />
                </Stack>
            )}
        </>
    );
};

export default List;
