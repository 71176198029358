import { useQuery } from 'react-query';

import { CUSTOMER } from '../../../constans/queryKeys';
import { viewCustomer } from '../../../services/customer';

export function useCustomer(id: number) {
    const { data, isLoading, isError, isFetching } = useQuery([CUSTOMER], () => viewCustomer(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        retry: 1,
        cacheTime: 500,
    });

    return { data, isLoading, isError, isFetching };
}
