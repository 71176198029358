/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import HotelIcon from '@mui/icons-material/Hotel';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import uuid from 'react-uuid';
import { COLORS as MAIN_COLORS } from '../../../../constans/colors';
import { DATE_FORMATS } from '../../../../helpers/dateFormats';
import { getFormattedDateTimeString } from '../../../../helpers/dateUtils';
import { TIME_FORMATS } from '../../../../helpers/timeFormats';
import { Benefit } from '../../../hotels/api/types';
import { BookingStatus, ChangeLog } from '../../api/types';
import CustomFields from './CustomFields';
import GeneralObject from './GeneralObject';
import { LABELS } from './Labels';
import { COLORS } from './statusColors';
import Transactions from './Transactions';

const useStyles = makeStyles({
    strikethroughText: {
        fontSize: '0.8rem',
        color: MAIN_COLORS.SLATE_GREY,
        textDecoration: 'line-through',
        paddingLeft: '0.5rem',
    },
});

interface Props {
    changeLogs: ChangeLog[] | undefined;
}

const ChangeLogTable = ({ changeLogs }: Props) => {
    const classes = useStyles();

    if (changeLogs === undefined || changeLogs === null || changeLogs.length < 1) {
        return (
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableBody>
                    <TableRow style={{ backgroundColor: '#f2f4f5' }}>
                        <TableCell>No change log found!</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#f2f4f5' }}>
                            <TableCell style={{ width: '20%', borderRight: '1px solid #e8e8e8' }}>Event</TableCell>
                            <TableCell align="left">Details</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {changeLogs.map((changeLog: ChangeLog) => (
                            <TableRow key={uuid()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell
                                    key={uuid()}
                                    component="th"
                                    scope="row"
                                    style={{
                                        verticalAlign: 'top',
                                        borderRight: '1px solid #e8e8e8',
                                    }}
                                >
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                color: `${
                                                    changeLog.details.status !== undefined
                                                        ? COLORS[changeLog.details.status as keyof typeof COLORS]
                                                        : COLORS.BOOKING_UPDATE_PENDING
                                                }`,
                                                paddingLeft: '1.1rem',
                                            }}
                                        >
                                            <AnnouncementIcon fontSize="small"></AnnouncementIcon>
                                        </Grid>
                                        <Grid item xs={10} style={{ paddingLeft: '1.1rem' }}>
                                            <Typography
                                                style={{
                                                    color: `${
                                                        changeLog.details.status !== undefined
                                                            ? COLORS[changeLog.details.status as keyof typeof COLORS]
                                                            : COLORS.BOOKING_UPDATE_PENDING
                                                    }`,
                                                    fontWeight: 'bold',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {changeLog.version === 1
                                                    ? 'Created'
                                                    : changeLog.details.status !== undefined
                                                      ? LABELS[changeLog.details.status as keyof typeof LABELS]
                                                      : 'Updated'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={2} style={{ paddingLeft: '1.1rem' }}>
                                            <PersonIcon
                                                fontSize="small"
                                                sx={{
                                                    color: `${COLORS.BOOKING_PENDING}`,
                                                }}
                                            ></PersonIcon>
                                        </Grid>
                                        <Grid item xs={10} style={{ paddingLeft: '1.1rem' }}>
                                            <Typography style={{ fontSize: '0.8rem' }}>
                                                {changeLog.user === null
                                                    ? 'Integration'
                                                    : `${changeLog.user.first_name} ${changeLog.user.last_name}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={2} style={{ paddingLeft: '1.1rem' }}>
                                            {changeLog.user?.is_backoffice && (
                                                <FormatBoldIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: `${COLORS.BOOKING_PENDING}`,
                                                    }}
                                                ></FormatBoldIcon>
                                            )}
                                            {!changeLog.user?.is_backoffice && changeLog.user?.customers[0] !== undefined && (
                                                <CorporateFareIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: `${COLORS.BOOKING_PENDING}`,
                                                    }}
                                                ></CorporateFareIcon>
                                            )}
                                            {!changeLog.user?.is_backoffice && changeLog.user?.hotels[0] !== undefined && (
                                                <HotelIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: `${COLORS.BOOKING_PENDING}`,
                                                    }}
                                                ></HotelIcon>
                                            )}
                                        </Grid>
                                        <Grid item xs={10} style={{ paddingLeft: '1.1rem' }}>
                                            <Typography style={{ fontSize: '0.8rem' }}>
                                                {changeLog.user?.hotels[0] !== undefined
                                                    ? changeLog.user?.hotels[0].name
                                                    : changeLog.user?.customers[0].name}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={2} style={{ paddingLeft: '1.1rem' }}>
                                            <ScheduleIcon fontSize="small" sx={{ color: '#a7bfcc' }}></ScheduleIcon>
                                        </Grid>
                                        <Grid item xs={10} style={{ paddingLeft: '1.1rem' }}>
                                            <Typography style={{ fontSize: '0.8rem' }}>
                                                {getFormattedDateTimeString(
                                                    changeLog.createdAt,
                                                    DATE_FORMATS['DD MMM YYYY'],
                                                    TIME_FORMATS['HH:mm'],
                                                    true,
                                                    true
                                                )}
                                                <span
                                                    style={{
                                                        paddingLeft: '0.2rem',
                                                        fontSize: '0.6rem',
                                                    }}
                                                >
                                                    (UTC)
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    style={{
                                        verticalAlign: 'top',
                                    }}
                                >
                                    {Object.keys(changeLog.details).map((key: string) => {
                                        if (
                                            ![
                                                'benefits',
                                                'customer',
                                                'hotel',
                                                'guest',
                                                'contract',
                                                'commissionContract',
                                                'feeContract',
                                            ].includes(key) &&
                                            changeLog.details[key] === null
                                        ) {
                                            return (
                                                <Typography key={key}>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        {LABELS[key as keyof typeof LABELS]} :
                                                    </span>
                                                    {changeLog.version > 1 && (
                                                        <span>
                                                            <span className={classes.strikethroughText}>
                                                                {
                                                                    changeLogs.find(
                                                                        change =>
                                                                            change.version < changeLog.version &&
                                                                            change.details[key] !== undefined
                                                                    )?.details[key]
                                                                }
                                                            </span>
                                                            <span
                                                                style={{
                                                                    paddingLeft: '0.5rem',
                                                                    color: MAIN_COLORS.SLATE_GREY,
                                                                }}
                                                            >
                                                                &#8594;
                                                            </span>
                                                        </span>
                                                    )}
                                                </Typography>
                                            );
                                        }

                                        if (key === 'benefits') {
                                            return (
                                                <Typography key={key}>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        {LABELS[key as keyof typeof LABELS]} :
                                                    </span>
                                                    {changeLog.version > 1 && (
                                                        <span>
                                                            <span className={classes.strikethroughText}>
                                                                {changeLogs
                                                                    .find(
                                                                        change =>
                                                                            change.version < changeLog.version &&
                                                                            change.details.benefits !== undefined
                                                                    )
                                                                    ?.details.benefits.map(
                                                                        (benefit: Benefit) => `${benefit.name}, `
                                                                    )}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    paddingLeft: '0.5rem',
                                                                    color: MAIN_COLORS.SLATE_GREY,
                                                                }}
                                                            >
                                                                &#8594;
                                                            </span>
                                                        </span>
                                                    )}
                                                    <span style={{ paddingLeft: '0.5rem' }}>
                                                        {changeLog.details.benefits.map(
                                                            (benefit: Benefit) => `${benefit.name}, `
                                                        )}
                                                    </span>
                                                </Typography>
                                            );
                                        }

                                        if (key === 'transactions') {
                                            const previousLog = changeLogs.find(
                                                change =>
                                                    change.version < changeLog.version &&
                                                    change.details.transactions !== undefined
                                            );

                                            return (
                                                <Typography key={key}>
                                                    {changeLog.version > 1 && previousLog !== undefined && (
                                                        <span>
                                                            <span
                                                                className={classes.strikethroughText}
                                                                style={{
                                                                    paddingLeft: '0',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: '0.9rem',
                                                                    }}
                                                                >
                                                                    {LABELS[key as keyof typeof LABELS]} :
                                                                </span>{' '}
                                                                <span
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: '0.9rem',
                                                                    }}
                                                                >
                                                                    &#123;
                                                                </span>
                                                                <Transactions
                                                                    transactions={previousLog.details.transactions}
                                                                ></Transactions>
                                                            </span>
                                                            <div
                                                                style={{
                                                                    color: MAIN_COLORS.SLATE_GREY,
                                                                    fontWeight: 'bold',
                                                                    fontSize: '1.2rem',
                                                                }}
                                                            >
                                                                &#8623;
                                                            </div>
                                                        </span>
                                                    )}
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        {LABELS[key as keyof typeof LABELS]} :
                                                    </span>{' '}
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        &#123;
                                                    </span>
                                                    <Transactions transactions={changeLog.details.transactions}></Transactions>
                                                </Typography>
                                            );
                                        }

                                        if (key === 'customFields') {
                                            return (
                                                <CustomFields
                                                    key={key}
                                                    fields={changeLog.details.customFields}
                                                    version={changeLog.version}
                                                    changeLogs={changeLogs}
                                                />
                                            );
                                        }

                                        if (key === 'status') {
                                            return (
                                                <Typography key={key}>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        {LABELS[key as keyof typeof LABELS]} :
                                                    </span>
                                                    {changeLog.version > 1 && (
                                                        <span>
                                                            <span className={classes.strikethroughText}>
                                                                {
                                                                    BookingStatus[
                                                                        changeLogs.find(
                                                                            change =>
                                                                                change.version < changeLog.version &&
                                                                                change.details.status !== undefined
                                                                        )?.details.status as keyof typeof BookingStatus
                                                                    ]
                                                                }
                                                            </span>

                                                            <span
                                                                style={{
                                                                    paddingLeft: '0.5rem',
                                                                    color: MAIN_COLORS.SLATE_GREY,
                                                                }}
                                                            >
                                                                &#8594;
                                                            </span>
                                                        </span>
                                                    )}
                                                    <span style={{ paddingLeft: '0.5rem' }}>
                                                        {BookingStatus[changeLog.details.status as keyof typeof BookingStatus]}
                                                    </span>
                                                </Typography>
                                            );
                                        }

                                        if (typeof changeLog.details[key] === 'boolean') {
                                            return (
                                                <Typography key={key}>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        {LABELS[key as keyof typeof LABELS]} :
                                                    </span>
                                                    {changeLog.version > 1 && (
                                                        <span>
                                                            <span className={classes.strikethroughText}>
                                                                {changeLogs.find(
                                                                    change =>
                                                                        change.version < changeLog.version &&
                                                                        change.details[key] !== undefined
                                                                )?.details[key]
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    paddingLeft: '0.5rem',
                                                                    color: MAIN_COLORS.SLATE_GREY,
                                                                }}
                                                            >
                                                                &#8594;
                                                            </span>
                                                        </span>
                                                    )}
                                                    <span style={{ paddingLeft: '0.5rem' }}>
                                                        {changeLog.details[key] ? 'Yes' : 'No'}
                                                    </span>
                                                </Typography>
                                            );
                                        }

                                        if (
                                            [
                                                'customer',
                                                'hotel',
                                                'guest',
                                                'contract',
                                                'commissionContract',
                                                'feeContract',
                                            ].includes(key)
                                        ) {
                                            return (
                                                <GeneralObject
                                                    object={changeLog.details[key]}
                                                    key={key}
                                                    parent={key}
                                                    version={changeLog.version}
                                                    changeLogs={changeLogs}
                                                />
                                            );
                                        }

                                        if (['string', 'number'].includes(typeof changeLog.details[key])) {
                                            return (
                                                <Typography key={key}>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                        }}
                                                    >
                                                        {LABELS[key as keyof typeof LABELS]} :
                                                    </span>
                                                    {changeLog.version > 1 && (
                                                        <span>
                                                            <span className={classes.strikethroughText}>
                                                                {
                                                                    changeLogs.find(
                                                                        change =>
                                                                            change.version < changeLog.version &&
                                                                            change.details[key] !== undefined
                                                                    )?.details[key]
                                                                }
                                                            </span>
                                                            <span
                                                                style={{
                                                                    paddingLeft: '0.5rem',
                                                                    color: MAIN_COLORS.SLATE_GREY,
                                                                }}
                                                            >
                                                                &#8594;
                                                            </span>
                                                        </span>
                                                    )}
                                                    <span style={{ paddingLeft: '0.5rem' }}>{changeLog.details[key]}</span>
                                                </Typography>
                                            );
                                        }

                                        return (
                                            <Typography key={key}>
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '0.9rem',
                                                    }}
                                                >
                                                    {LABELS[key as keyof typeof LABELS]} :
                                                </span>{' '}
                                                Unknown
                                            </Typography>
                                        );
                                    })}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ChangeLogTable;
