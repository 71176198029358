import { Link } from '@mui/material';
import { FunctionComponent } from 'react';

import { COLORS } from '../constans/colors';

interface SimpleLinkProps {
    label: string;
    link: string;
}

const SimpleLink: FunctionComponent<SimpleLinkProps> = ({ label, link }) => {
    return (
        <Link
            href={link}
            underline="none"
            sx={{
                color: COLORS.BLUE_TEXT,
                ':hover': {
                    color: COLORS.SLATE_GREY,
                },
            }}
        >
            {label}
        </Link>
    );
};

export default SimpleLink;
