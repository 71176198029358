import { useQuery } from 'react-query';
import { HOTEL } from '../../../constans/queryKeys';

import { REQUESTS } from '../../../constans/urlPaths';
import { listRequests } from '../../../services/request';
import { BookingVariables } from './types';

export const useRequestSearch = (variables: Partial<BookingVariables>) => {
    const { page = 1, customerId, activeTab, searchType, searchValue } = variables;

    return useQuery(
        [(activeTab ? activeTab + page : HOTEL + page) + REQUESTS],
        () =>
            listRequests({
                page,
                customerId,
                activeTab,
                searchType,
                searchValue,
            }),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            cacheTime: 500,
        }
    );
};
