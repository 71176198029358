import { PrimaryButton, SecondaryButton, Form, TextField } from '@get-e/react-components';
import { Card, CardContent, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AxiosError } from 'axios';
import moment from 'moment';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import {
    BUTTON_SAVE_HOTEL,
    SCREEN_CREATE_EDIT_HOTEL,
    INPUT_CREATE_HOTEL_NAME,
    INPUT_HOTEL_ADDRESS,
    INPUT_HOTEL_EMAIL,
    INPUT_HOTEL_PHONE,
} from '../../../amplitude/amplKeys';
import { GooglePlacesAutocomplete } from '../../../components/GooglePlacesAutocomplete';
import PageTitle from '../../../components/PageTitle';
import { ACCOUNTS_HOTELS } from '../../../constans/urlPaths';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { getCurrentUserCountryCode } from '../../../helpers/getCurrentUserCountryCode';
import useEffectAsync from '../../../helpers/useEffectAsync';
import allValid from '../../../helpers/validation/allValid';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import isEmail from '../../../helpers/validation/validators/isEmail';
import isFilledObject from '../../../helpers/validation/validators/isFilledObject';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import isPhoneNumber from '../../../helpers/validation/validators/isPhoneNumber';
import { getTimeZone } from '../../../services/getTimeZone';
import { createHotel } from '../../../services/hotel';
import { AddressRequest } from '../api/types';

const useStyles = makeStyles({
    heading: {},
    details: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: '2rem',
    },
    formContainer: {
        maxWidth: '442px',
    },
    buttonCancle: {
        marginLeft: '2rem',
    },
});

interface CreateHotelProps {
    onCloseModal?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onHotelCreated?: (hotel: any) => void;
}

const CreateHotel = ({ onCloseModal, onHotelCreated }: CreateHotelProps) => {
    const autoFocusRef = useRef<HTMLInputElement>();
    const [hotelName, setHotelName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [hotelNameError, setHotelNameError] = useState<InputError | null>(null);
    const [phoneError, setPhoneError] = useState<InputError | null>(null);
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const [addressError, setAddressError] = useState<InputError | null>(null);
    const [address, setAddress] = useState<AddressRequest>({} as AddressRequest);
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();
    const defaultCountryCode = getCurrentUserCountryCode();

    useEffect(() => {
        logAmplitudeEvent(SCREEN_CREATE_EDIT_HOTEL);
    }, []);

    useEffect(() => {
        logAmplitudeEvent(INPUT_HOTEL_ADDRESS);
    }, [address]);

    const { mutate: createHotelMutation, isLoading: isLoadingCreate } = useMutation(createHotel, {
        onSuccess: response => {
            if (onHotelCreated) {
                onHotelCreated(response.data);
                return;
            }

            if (onCloseModal) {
                onCloseModal();
                return;
            }

            navigate(ACCOUNTS_HOTELS);
            showNotification('Hotel successfully created.', Severity.Info);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    const validateFormFields = (): boolean => {
        const validated = {
            name: isFilledString(hotelName, InputError.Required),
            phone: isPhoneNumber(phone, InputError.InvalidPhoneNumber),
            email: isEmail(email, InputError.InvalidEmail),
            address: isFilledObject(address, InputError.Required),
        };

        if (!allValid(validated)) {
            setAddressError(validated.address.isValid ? null : validated.address?.error);
            setPhoneError(validated.phone.isValid ? null : validated.phone.error);
            setEmailError(validated.email.isValid ? null : validated.email.error);
            setHotelNameError(validated.name.isValid ? null : validated.name.error);
            return false;
        }

        return true;
    };

    useEffectAsync(async () => {
        if (address && Object.keys(address).length !== 0) {
            const timeZone = await getTimeZone(address.lat ?? '', address.long ?? '', moment.utc().valueOf() / 1000);

            // eslint-disable-next-line require-atomic-updates
            address.timeZone = timeZone.timeZoneId;
        }
    }, [address]);

    const handleSubmit = () => {
        logAmplitudeEvent(BUTTON_SAVE_HOTEL);

        if (validateFormFields()) {
            createHotelMutation({
                name: hotelName,
                hotelAddress: address,
                phoneNumber: phone?.replace(/\s/g, ''),
                email,
                notes: 'TODO',
            });
        }
    };

    const handleCancel = () => {
        if (onCloseModal) {
            onCloseModal();
            return;
        }

        navigate(-1);
    };

    return (
        <Form className={classes.formContainer} onSubmit={handleSubmit}>
            <PageTitle title={'Create hotel'} />
            <Grid item xs={12} md={12} xl={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container columnSpacing={1} rowSpacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    value={hotelName}
                                    onChange={event => {
                                        setHotelName(event.target.value);
                                        setHotelNameError(null);
                                    }}
                                    label={'Hotel name'}
                                    type="text"
                                    autoFocus
                                    name="hotelName"
                                    inputRef={autoFocusRef}
                                    required
                                    error={hotelNameError !== null}
                                    helperText={getHelperText(hotelNameError, t)}
                                    onFocus={() => logAmplitudeEvent(INPUT_CREATE_HOTEL_NAME)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GooglePlacesAutocomplete
                                    error={addressError}
                                    helperText={getHelperText(addressError, t)}
                                    onChangeError={setAddressError}
                                    onChangeAddress={setAddress}
                                    label="Address"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MuiTelInput
                                    defaultCountry={defaultCountryCode}
                                    label="Phone"
                                    value={phone}
                                    onChange={value => {
                                        setPhone(value);
                                        setPhoneError(null);
                                    }}
                                    name="phone"
                                    error={phoneError !== null}
                                    helperText={getHelperText(phoneError, t)}
                                    fullWidth
                                    variant="filled"
                                    required
                                    onFocus={() => logAmplitudeEvent(INPUT_HOTEL_PHONE)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={email}
                                    onChange={event => {
                                        setEmail(event.target.value);
                                        setEmailError(null);
                                    }}
                                    label="Email"
                                    type="text"
                                    name="email"
                                    required
                                    error={emailError !== null}
                                    helperText={getHelperText(emailError, t)}
                                    onFocus={() => logAmplitudeEvent(INPUT_HOTEL_EMAIL)}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} marginTop="2rem">
                <PrimaryButton onClick={handleSubmit} submitsForm loading={isLoadingCreate}>
                    Create
                </PrimaryButton>
                <SecondaryButton onClick={handleCancel} className={classes.buttonCancle}>
                    Cancel
                </SecondaryButton>
            </Grid>
        </Form>
    );
};

export default CreateHotel;
