import { useFlags } from 'launchdarkly-react-client-sdk';

import SimpleLink from '../../components/SimpleLink';
import { BILLING_STATEMENTS } from '../../constans/urlPaths';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import { SCOPES } from '../../permissions/permissionMaps';
import PermissionsGate from '../../permissions/PermissionsGate';
import Statements from './Statements';

export const billingStatements: PageDefinition = {
    path: BILLING_STATEMENTS,
    Component() {
        const { useFinancialReconcilliation } = useFlags();

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Billing" link={BILLING_STATEMENTS} />,
                    }}
                >
                    <Statements />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};
