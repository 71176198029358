import { TFunction } from 'i18next';
import InputError from './InputError';

export default function getHelperText(error: InputError | null, t: TFunction): string {
    switch (error) {
        case null:
            return '';
        case InputError.Required:
            return t('errors.forms.inputs.empty');
        case InputError.InvalidEmail:
            return t('errors.forms.inputs.invalidEmail');
        case InputError.NoMatch:
            return t('errors.forms.inputs.noMatch');
        case InputError.InvalidPassword:
            return t('errors.forms.inputs.invalidPassword');
        case InputError.NotStrongPassword:
            return t('errors.forms.inputs.notStrongPassword');
        case InputError.InvalidPhoneNumber:
            return t('errors.forms.inputs.invalidPhoneNumber');
        case InputError.InvalidAfterDate:
            return t('errors.forms.inputs.invalidAfterDate');
        case InputError.InvalidPastDate:
            return t('errors.forms.inputs.invalidPastDate');
        case InputError.EmptyEmail:
            return t('errors.forms.inputs.emptyEmail');
        case InputError.InvalidAfterTime:
            return t('errors.forms.inputs.invalidAfterTime');
        default:
            throw new Error('Unhandled error');
    }
}
