import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../../../constans/colors';

const useStyles = makeStyles({
    secondaryText: {
        color: COLORS.DARK_GRAY,
    },
    mainContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    user: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '1.2rem',
    },
    userIcon: {
        marginRight: '1.2rem',
    },
});

interface BookingGuestProps {
    firstName: string;
    lastName: string;
    employeeId: string;
    customerName: string;
}

const RequestGuest = ({ firstName, lastName, employeeId, customerName }: BookingGuestProps) => {
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <div className={classes.user}>
                <PersonIcon fontSize="small" className={classes.userIcon} />
                <Typography variant="body1">
                    {firstName}&nbsp;{lastName}
                    {employeeId && <span className={classes.secondaryText}>&nbsp; {employeeId}</span>}
                </Typography>
            </div>
            <div className={classes.user}>
                <BusinessIcon fontSize="small" className={classes.userIcon} />
                <Typography variant="body1">{customerName}</Typography>
            </div>
        </div>
    );
};

export default RequestGuest;
