import { AxiosError } from 'axios';
import {
    CreateBooking,
    EditBooking,
    Booking,
    BookingVariables,
    PaginationType,
    SearchResponse,
    SearchOptionsForBookingResponse,
} from '../pages/reservations/api/types';
import apiClient from './api';
import { BOOKINGS, OPTION_SEARCH_FOR_BOOKINGS } from './routes';

export interface BookingResponse {
    data: Booking[];
    meta: PaginationType;
    counts: {
        all?: number | null;
        pending?: number | null;
        upcoming?: number | null;
        inProgress?: number | null;
        archive?: number | null;
    };
}

export const viewBooking = async (id: number): Promise<Booking> => {
    const { data } = await apiClient.get<Booking>(`${BOOKINGS}/${id}`);

    return data;
};

export const createBooking = async (payload: CreateBooking) => {
    const response = await apiClient.post<Booking>(BOOKINGS, payload);

    return response;
};

export const editBooking = async (variables: EditBooking) => {
    const { id, payload } = variables;

    try {
        const response = await apiClient.put<Booking>(`${BOOKINGS}/${id}`, payload);

        return response;
    } catch (error) {
        if (error instanceof AxiosError) {
            throw Error(error.response?.data.message);
        }
    }
};

export const listBookings = async (variables: Partial<BookingVariables>): Promise<BookingResponse> => {
    const { page = 1, activeTab, customerId, searchType, searchValue, startDate, endDate } = variables;

    const searchParams = new URLSearchParams({
        page: page.toString(),
        activeTab: activeTab?.toString() ?? '',
        customerId: customerId?.toString() ?? '',
        searchType: searchType ?? '',
        searchValue: searchValue ?? '',
        startDate: startDate ?? '',
        endDate: endDate ?? '',
    }).toString();

    const { data } = await apiClient.get<BookingResponse>(`${BOOKINGS}?${searchParams}`);

    return data;
};

export const searchOptionsForBooking = async (search: string): Promise<SearchOptionsForBookingResponse> => {
    const { data } = await apiClient.get<SearchResponse>(`${OPTION_SEARCH_FOR_BOOKINGS}/${search}`);

    return data;
};
