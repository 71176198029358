import { AxiosResponse } from 'axios';

import {
    AddTravellerRequest,
    AddTravellerResponse,
    Benefit,
    RoomType,
    Traveller,
    TravellerResponse,
} from '../pages/hotels/api/types';
import apiClient from './api';
import { ADD_TRAVELLER, BENEFITS, getTravellers, ROOM_TYPES } from './routes';

export const mapAddResponseToTraveller = (traveller: AddTravellerResponse): Traveller => ({
    id: traveller.id,
    firstName: traveller.first_name,
    lastName: traveller.last_name,
    email: traveller.email,
    mobile: traveller.phone_number,
    employeeNumber: traveller.employee_number,
});

export const createTraveller = async (variables: Partial<AddTravellerRequest>): Promise<AxiosResponse<AddTravellerResponse>> => {
    const { firstName, lastName, email, mobile, employeeNumber, customerId } = variables;

    const response = await apiClient.post<AddTravellerResponse>(ADD_TRAVELLER, {
        firstName,
        lastName,
        email,
        phoneNumber: mobile,
        employeeNumber,
        customerId,
    });

    return response;
};

interface Travellers {
    data: TravellerResponse[];
    meta: {
        current_page: number;
        from: number;
        last_page: number;
        per_page: number;
        to: number;
        total: number;
    };
}

export const listTravellers = async (search: string, customerId: number): Promise<TravellerResponse[]> => {
    const { data } = await apiClient.get<Travellers>(getTravellers(customerId, search ?? ''));

    return data?.data;
};

export const listRoomTypes = async (): Promise<RoomType[]> => {
    const { data } = await apiClient.get<RoomType[]>(`${ROOM_TYPES}`);

    return data;
};

export const listBenefits = async (): Promise<Benefit[]> => {
    const { data } = await apiClient.get<Benefit[]>(`${BENEFITS}`);

    return data;
};
