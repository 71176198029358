import { IS_PHONE_NUMBER } from '../regexRules';
import { Validated } from '../Validator';

export default function isPhoneNumber<TError>(value: string, error: TError): Validated<string, TError> {
    return !IS_PHONE_NUMBER.test(value)
        ? {
              isValid: false,
              error,
          }
        : {
              isValid: true,
              value,
          };
}
