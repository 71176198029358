import { PrimaryButton } from '@get-e/react-components';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../constans/colors';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '20rem',
    },
    icon: {
        height: '9.5rem',
        width: '8rem',
    },
});

interface ErrorPageProps {
    icon: string;
    header: string;
    description: string;
    buttonTitle?: string;
    onButtonClick?: () => void;
    isButtonVisable?: boolean;
}

const ErrorPage = ({
    onButtonClick = () => {},
    icon,
    header,
    description,
    buttonTitle = '',
    isButtonVisable = true,
}: ErrorPageProps) => {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh', background: COLORS.LIGHT_GRAY }}
        >
            <div className={classes.container}>
                <img src={icon} alt={header} className={classes.icon} />
                <Typography color={COLORS.BLUE} mb={'.5rem'} fontWeight="700" alignSelf="flex-start">
                    {header}
                </Typography>
                <Typography mb={'2rem'}>{description}</Typography>
                {isButtonVisable && (
                    <PrimaryButton onClick={onButtonClick} fullWidth>
                        {buttonTitle}
                    </PrimaryButton>
                )}
            </div>
        </Grid>
    );
};

export default ErrorPage;
