import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS as MAIN_COLORS } from '../../../../constans/colors';
import { ChangeLog } from '../../api/types';
import { LABELS } from './Labels';

const useStyles = makeStyles({
    strikethroughText: {
        fontSize: '0.8rem',
        color: MAIN_COLORS.SLATE_GREY,
        textDecoration: 'line-through',
        paddingLeft: '0.5rem',
    },
});

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    object: any;
    parent: string;
    version: number;
    changeLogs: ChangeLog[];
}

const GeneralObject = ({ object, parent, version, changeLogs }: Props) => {
    const classes = useStyles();

    // Find previous log containing the modified custom field
    const previousLog = changeLogs.find(change => change.version < version && change.details[parent] !== undefined);

    return (
        <div>
            {/* If the object (ex: new commissionContract, new feeContract) is NOT null iterat on new log's keys*/}
            {object &&
                Object.keys(object).map((key: string) => {
                    if (['string', 'number'].includes(typeof object[key])) {
                        return (
                            <Typography key={key}>
                                <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    {LABELS[`${parent}_${key}` as keyof typeof LABELS]} :
                                </span>
                                {version > 1 && previousLog !== undefined && (
                                    <span>
                                        <span className={classes.strikethroughText}>{previousLog.details[parent][key]}</span>
                                        <span
                                            style={{
                                                paddingLeft: '0.5rem',
                                                color: MAIN_COLORS.SLATE_GREY,
                                            }}
                                        >
                                            &#8594;
                                        </span>
                                    </span>
                                )}
                                <span style={{ paddingLeft: '0.5rem' }}>{object[key]}</span>
                            </Typography>
                        );
                    }

                    if (key === 'address') {
                        return (
                            <Typography key={key}>
                                <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    {LABELS[`${parent}_${key}` as keyof typeof LABELS]} :
                                </span>
                                {version > 1 && previousLog !== undefined && (
                                    <span>
                                        <span className={classes.strikethroughText}>
                                            {previousLog.details[parent][key].addressLine}
                                        </span>
                                        <span
                                            style={{
                                                paddingLeft: '0.5rem',
                                                color: MAIN_COLORS.SLATE_GREY,
                                            }}
                                        >
                                            &#8594;
                                        </span>
                                    </span>
                                )}
                                <span style={{ paddingLeft: '0.5rem' }}>{object[key].addressLine}</span>
                            </Typography>
                        );
                    }

                    if (key === 'currency') {
                        return (
                            <Typography key={key}>
                                <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    {LABELS[`${parent}_${key}` as keyof typeof LABELS]} :
                                </span>
                                {version > 1 && previousLog !== undefined && (
                                    <span>
                                        <span className={classes.strikethroughText}>{previousLog.details[parent][key].iso}</span>
                                        <span
                                            style={{
                                                paddingLeft: '0.5rem',
                                                color: MAIN_COLORS.SLATE_GREY,
                                            }}
                                        >
                                            &#8594;
                                        </span>
                                    </span>
                                )}
                                <span style={{ paddingLeft: '0.5rem' }}>{object[key].iso}</span>
                            </Typography>
                        );
                    }

                    return (
                        <Typography key={key}>
                            <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                {LABELS[`${parent}_${key}` as keyof typeof LABELS]} :
                            </span>{' '}
                            Unknown
                        </Typography>
                    );
                })}

            {/* If the object (ex: new commissionContract, new feeContract) is null iterat on prevoius log's keys*/}
            {!object &&
                previousLog &&
                Object.keys(previousLog.details[parent]).map((key: string) => {
                    if (['string', 'number'].includes(typeof previousLog?.details[parent][key])) {
                        return (
                            <Typography key={key}>
                                <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    {LABELS[`${parent}_${key}` as keyof typeof LABELS]} :
                                </span>
                                {version > 1 && previousLog !== undefined && (
                                    <span>
                                        <span className={classes.strikethroughText}>{previousLog.details[parent][key]}</span>
                                        <span
                                            style={{
                                                paddingLeft: '0.5rem',
                                                color: MAIN_COLORS.SLATE_GREY,
                                            }}
                                        >
                                            &#8594;
                                        </span>
                                    </span>
                                )}
                                <span style={{ paddingLeft: '0.5rem' }}></span>
                            </Typography>
                        );
                    }

                    if (key === 'address') {
                        return (
                            <Typography key={key}>
                                <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    {LABELS[`${parent}_${key}` as keyof typeof LABELS]} :
                                </span>
                                {version > 1 && previousLog !== undefined && (
                                    <span>
                                        <span className={classes.strikethroughText}>
                                            {previousLog.details[parent][key].addressLine}
                                        </span>
                                        <span
                                            style={{
                                                paddingLeft: '0.5rem',
                                                color: MAIN_COLORS.SLATE_GREY,
                                            }}
                                        >
                                            &#8594;
                                        </span>
                                    </span>
                                )}
                                <span style={{ paddingLeft: '0.5rem' }}></span>
                            </Typography>
                        );
                    }

                    if (key === 'currency') {
                        return (
                            <Typography key={key}>
                                <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    {LABELS[`${parent}_${key}` as keyof typeof LABELS]} :
                                </span>
                                {version > 1 && previousLog !== undefined && (
                                    <span>
                                        <span className={classes.strikethroughText}>{previousLog.details[parent][key].iso}</span>
                                        <span
                                            style={{
                                                paddingLeft: '0.5rem',
                                                color: MAIN_COLORS.SLATE_GREY,
                                            }}
                                        >
                                            &#8594;
                                        </span>
                                    </span>
                                )}
                                <span style={{ paddingLeft: '0.5rem' }}></span>
                            </Typography>
                        );
                    }

                    return (
                        <Typography key={key}>
                            <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                {LABELS[`${parent}_${key}` as keyof typeof LABELS]} :
                            </span>{' '}
                            Unknown
                        </Typography>
                    );
                })}
        </div>
    );
};

export default GeneralObject;
