import CenterContainer from '../../components/CenterContainer';
import Retry from '../../components/Retry';

const ErrorBoundaryPage = () => {
    return (
        <CenterContainer>
            <Retry onRetry={() => console.log('Retrying')} />
        </CenterContainer>
    );
};

export default ErrorBoundaryPage;
