import { useFlags } from 'launchdarkly-react-client-sdk';

import SimpleLink from '../../../components/SimpleLink';
import { EDIT_INVOICE, UPLOAD_INVOICE } from '../../../constans/urlPaths';
import { PageDefinition } from '../../../createRoutes';
import Page from '../../../layouts/Page';
import { SCOPES } from '../../../permissions/permissionMaps';
import PermissionsGate from '../../../permissions/PermissionsGate';
import UploadInvoice from './UploadInvoice';

export const uploadInvoice: PageDefinition = {
    path: UPLOAD_INVOICE,
    Component() {
        const { useFinancialReconcilliation } = useFlags();

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Upload Invoice" link={UPLOAD_INVOICE} />,
                    }}
                >
                    <UploadInvoice />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};

export const editInvoice: PageDefinition = {
    path: EDIT_INVOICE,
    Component() {
        const { useFinancialReconcilliation } = useFlags();

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Edit Invoice" link={EDIT_INVOICE} />,
                    }}
                >
                    <UploadInvoice />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};
