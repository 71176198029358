import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { FunctionComponent, useState } from 'react';

interface ShowPasswordButtonProps {
    onShowPassword: (isVisible: boolean) => void;
}

const ShowPasswordButton: FunctionComponent<ShowPasswordButtonProps> = ({ onShowPassword }) => {
    const [showingPassword, setShowingPassword] = useState(false);

    const handleClick = () => {
        const isPasswordVisible = !showingPassword;

        setShowingPassword(isPasswordVisible);
        onShowPassword(isPasswordVisible);
    };

    return (
        <InputAdornment position="end">
            <IconButton
                aria-label={showingPassword ? 'Hide password' : 'Show password'}
                onClick={handleClick}
                onMouseDown={event => event.preventDefault()}
            >
                {showingPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );
};

export default ShowPasswordButton;
