import { PrimaryButton, SecondaryButton, SignedOutLayout, TextField } from '@get-e/react-components';
import { Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import PageTitle from '../../../components/PageTitle';
import { SIGN_IN } from '../../../constans/urlPaths';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import allValid from '../../../helpers/validation/allValid';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import and from '../../../helpers/validation/validators/and';
import isEmail from '../../../helpers/validation/validators/isEmail';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import { logoIcon } from '../../../images';
import { forgotPassword } from '../../../services/users';

const useStyles = makeStyles({
    container: {
        minHeight: '100%',
    },
    emailField: {
        margin: '2rem 0',
    },
    buttons: {
        width: '45%',
    },
    signInButton: {
        width: '100%',
        marginTop: '2rem',
    },
    logoContainer: {
        display: 'block',
        margin: '0 auto',
        maxWidth: 110,
        marginBottom: '2rem',
    },
});

const ForgotPassword = () => {
    const [isSuccessCardVisible, setIsSuccessCardVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { showNotification } = useNotificationContext();
    const navigate = useNavigate();

    const { mutate: forgotPasswordMutation, isLoading } = useMutation(forgotPassword, {
        onSuccess: () => {
            showNotification('If your email is known a reset link had been sent.', Severity.Info);
            setIsSuccessCardVisible(true);
        },
        onError: (error: AxiosError<Error>) => {
            showNotification(error?.response?.data?.message || t('errors.retry'), Severity.Error);
        },
    });

    const validateFormFields = (): boolean => {
        const validated = {
            email: and(isFilledString(email, InputError.EmptyEmail), () => isEmail(email, InputError.InvalidEmail)),
        };

        if (!allValid(validated)) {
            setEmailError(validated.email.isValid ? null : validated.email.error);

            return false;
        }

        return true;
    };

    const handleSend = () => {
        if (validateFormFields()) {
            forgotPasswordMutation({
                email,
            });
        }
    };

    const handelBack = () => {
        navigate(-1);
    };

    const handleSignIn = () => {
        navigate(SIGN_IN);
    };

    return (
        <SignedOutLayout>
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
                <Card variant="outlined" sx={{ width: isMobile ? '100%' : '500px' }}>
                    {!isSuccessCardVisible && (
                        <CardContent sx={{ padding: isMobile ? '1rem' : '2rem' }}>
                            <Grid container direction="row" alignItems="center">
                                <img src={logoIcon} alt={'Logo'} className={classes.logoContainer} />
                            </Grid>
                            <PageTitle title="Forgot your password?" />
                            <Typography>Please enter the email you use to sign in</Typography>

                            <TextField
                                className={classes.emailField}
                                value={email}
                                onChange={event => {
                                    setEmail(event.target.value);
                                    setEmailError(null);
                                }}
                                label="Email"
                                type="text"
                                autoComplete="email"
                                name="email"
                                error={emailError !== null}
                                helperText={getHelperText(emailError, t)}
                                margin="normal"
                                required
                            />

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <PrimaryButton onClick={handleSend} className={classes.buttons} loading={isLoading}>
                                    Send
                                </PrimaryButton>
                                <SecondaryButton onClick={handelBack} className={classes.buttons}>
                                    Back
                                </SecondaryButton>
                            </Grid>
                        </CardContent>
                    )}
                    {isSuccessCardVisible && (
                        <CardContent sx={{ padding: isMobile ? '1rem' : '2rem' }}>
                            <Grid container direction="row" alignItems="center">
                                <img src={logoIcon} alt={'Logo'} className={classes.logoContainer} />
                            </Grid>
                            <PageTitle title="Your password has been reset" />
                            <Typography>
                                If your email address is known in our system, a link to change your password has been sent to the
                                email address provided. Pleasse check your inbox and click on the link provided.
                            </Typography>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <PrimaryButton onClick={handleSignIn} className={classes.signInButton}>
                                    Sign in
                                </PrimaryButton>
                            </Grid>
                        </CardContent>
                    )}
                </Card>
            </Grid>
        </SignedOutLayout>
    );
};

export default ForgotPassword;
