import { DataGrid } from '@get-e/react-components';
import { GridEventListener } from '@mui/x-data-grid';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { BUTTON_EDIT_HOTEL } from '../../../../amplitude/amplKeys';
import { LOCAL_STORAGE_KEYS } from '../../../../constans/localStorageKeys';
import { EDIT_HOTEL_BASE_URL } from '../../../../constans/urlPaths';
import { useHotels } from '../../api/useHotels';
import { columnsHotel } from '../../data';

import { useDataGridStyles } from '../../styles/useDataGridStyles';

interface HotelsProps {
    isActive: boolean;
    setTotalHotels: (totalHotels: number) => void;
}

const HotelsPanel = ({ isActive, setTotalHotels }: HotelsProps) => {
    const { hotels, isLoading, isError } = useHotels();
    const dataGridClasses = useDataGridStyles();
    const navigate = useNavigate();

    useEffect(() => {
        if (hotels) {
            setTotalHotels(hotels.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hotels.length]);

    const handleRowClick: GridEventListener<'rowClick'> = (params, event) => {
        event.preventDefault();
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCOUNT_EDITED, params.row.name ?? '');
        navigate(`${EDIT_HOTEL_BASE_URL}${params.id}`, {
            state: {
                accountName: params.row.name,
            },
        });
        logAmplitudeEvent(BUTTON_EDIT_HOTEL);
    };

    return (
        <div className={dataGridClasses.dataGrid}>
            {isActive && (
                <>
                    <DataGrid
                        className={clsx(dataGridClasses.dataGrid, !hotels.length ? dataGridClasses.dataGridNoRows : '')}
                        rows={hotels}
                        columns={columnsHotel()}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        autoHeight
                        noRows="No active hotels"
                        getRowHeight={() => 'auto'}
                        loading={isLoading && !isError}
                        onRowClick={handleRowClick}
                        noRowsClassName={dataGridClasses.noResultsWrapper}
                        hideFooter={!hotels.length}
                    />
                </>
            )}
        </div>
    );
};

export default HotelsPanel;
