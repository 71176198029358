import { DataGrid } from '@get-e/react-components';
import { useMediaQuery } from '@mui/material';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../../../context/AuthenticatedUserContext';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import useEffectAsync from '../../../../helpers/useEffectAsync';
import { emptyAirline } from '../../../../images';
import { revokeInvitedUser } from '../../../../services/hotelUsers';
import { useInvitedHotelUsers } from '../../api/useInvitedHotelUsers';
import { mapUserToTableRow } from '../../helpers/tableMapper';
import { getInivitedColumns } from '../gridData';
import { useDataGridStyles } from '../styles/useDataGridStyles';

interface InvitedProps {
    onUpdate: (invited: number) => void;
    isActive: boolean;
    inviteRefetch: boolean;
}

const InvitedPanel = ({ onUpdate, isActive, inviteRefetch }: InvitedProps) => {
    const [revokeId, setRevokeId] = useState<number | null>(null);
    const dataGridClasses = useDataGridStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { id = '' } = useParams();
    const { user } = useAuth();
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();

    const hotelId = (id && parseInt(id)) || (user?.hotel.id ?? -1);

    const { data = [], isFetching: isFetchingInvited, refetch } = useInvitedHotelUsers(hotelId);

    useEffect(() => {
        onUpdate?.(data?.length ?? 0);
    }, [data?.length, onUpdate]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteRefetch]);

    useEffectAsync(async () => {
        if (revokeId) {
            await revokeInvitedUser(hotelId, revokeId)
                .then(() => {
                    showNotification('User invitation successfully revoked.', Severity.Info);
                    refetch();
                })
                .catch((error: AxiosError<Error>) => {
                    showNotification(error.response?.data.message ?? t('errors.retry'), Severity.Error);
                });
        }
    }, [revokeId]);

    const onRevokeClicked = (idRevoked: number) => {
        setRevokeId(idRevoked);
    };

    const rows = data?.length ? mapUserToTableRow(data) : [];

    return (
        <div className={dataGridClasses.dataGrid}>
            {isActive && (
                <>
                    <DataGrid
                        className={clsx(dataGridClasses.dataGrid, !rows.length ? dataGridClasses.dataGridNoRows : '')}
                        disableColumnMenu
                        rows={isFetchingInvited ? [] : rows}
                        columns={getInivitedColumns(onRevokeClicked)}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        autoHeight
                        noRows="No result found"
                        loading={isFetchingInvited}
                        getRowHeight={() => (isMobile ? 'auto' : null)}
                        noResultImage={emptyAirline}
                        noRowsClassName={dataGridClasses.noResultsWrapper}
                        hideFooter={!rows.length}
                    />
                </>
            )}
        </div>
    );
};

export default InvitedPanel;
