import { useQuery } from 'react-query';

import { GET_INVOICE } from '../../../../constans/queryKeys';
import apiClient from '../../../../services/api';
import { INVOICES } from '../../../../services/routes';
import { Invoice } from '../../api/types';

export const useInvoice = (id: number) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([GET_INVOICE + id], () => getInvoice(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: Boolean(id),
    });

    return {
        data: (data as Invoice) ?? ({} as Invoice),
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getInvoice = async (id: number): Promise<Invoice> => {
    const { data } = await apiClient.get<Invoice>(`${INVOICES}/${id}`);

    return data;
};
