import { Grid, Typography } from '@mui/material';
import { DATE_FORMATS } from '../../../../helpers/dateFormats';
import { getFormattedDateTimeString } from '../../../../helpers/dateUtils';
import { TIME_FORMATS } from '../../../../helpers/timeFormats';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transactions: any[];
}

const Transactions = ({ transactions }: Props) => {
    return (
        <div>
            {transactions.map((t, key) => (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={key}>
                    <Grid item xs={1} style={{ paddingLeft: '2.2rem' }}>
                        <Typography style={{ fontSize: '0.9rem' }}>{t.isLocked && <span>&#128274;</span>}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: '0' }}>
                        <Typography style={{ fontSize: '0.9rem' }}>
                            {getFormattedDateTimeString(t.date, DATE_FORMATS['DD MMM YYYY'], TIME_FORMATS['HH:mm'], true, true)}
                        </Typography>
                    </Grid>
                    <Grid item xs={0.5} style={{ paddingLeft: '0' }}>
                        <Typography style={{ fontSize: '0.8rem', color: '#4f6978' }}>{t.currency.iso}</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ paddingLeft: '0' }}>
                        <Typography style={{ fontSize: '0.9rem' }}>
                            <span style={{ fontWeight: 'bold' }}>{t.unit}</span> &#10006;{' '}
                            <span style={{ fontWeight: 'bold' }}>{t.amount} </span>
                        </Typography>
                    </Grid>

                    <Grid item xs={6.5} style={{ paddingLeft: '0' }}>
                        <Typography style={{ fontSize: '0.9rem' }}>
                            <span style={{ fontStyle: 'italic' }}>{t.comment}</span>
                        </Typography>
                    </Grid>
                </Grid>
            ))}
            {<span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>&#125;</span>}
        </div>
    );
};

export default Transactions;
