import chameleon from '@chamaeleonidae/chmln';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { AuthProvider } from './context/AuthenticatedUserContext';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

(async () => {
    const initialLdContext = {
        kind: 'user',
        key: 'test@get-e.com',
        name: 'get-e',
        email: 'test@get-e.com',
    };

    if (window.location.hostname.includes('dev')) {
        chameleon.init('SUwKvhx3SxwlHMfJWSA5y00gzqd8tBCbSdRkvDQyM88tbx-1SGoRc-Fmz1SSd4kxC9Ws30', {
            fastUrl: 'https://fast.chameleon.io/',
        });
    }

    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
        context: initialLdContext,
    });

    root.render(
        <LDProvider>
            <BrowserRouter>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </BrowserRouter>
        </LDProvider>
    );
})();
