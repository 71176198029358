import downloadFile from '../../../helpers/downloadFile';
import apiClient from '../../../services/api';
import { getDownloadStatementRoute } from '../../../services/routes';

const downloadStatement = async (id: number): Promise<unknown> => {
    const result = await apiClient
        .get(getDownloadStatementRoute(id), { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            downloadFile(url, `GET-E Statement ${id}.csv`);
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default downloadStatement;
