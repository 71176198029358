import { Hotel, HotelResponse, HotelRoleResponse } from '../pages/accounts/api/types';
import apiClient from './api';
import { CREATE_HOTELS, getHotelRolesRoute, getHotelsNearbyRoute, HOTELS } from './routes';

export const listHotels = async (search?: string): Promise<HotelResponse[]> => {
    const { data } = await apiClient.get<HotelResponse[]>(`${HOTELS}?search=${search}`);

    return data;
};

export const listHotelsNearby = async (lat: string, long: string, search?: string): Promise<HotelResponse[]> => {
    const { data } = await apiClient.get<HotelResponse[]>(getHotelsNearbyRoute(lat, long, search));

    return data;
};

export const viewHotel = async (id: number): Promise<HotelResponse> => {
    const { data } = await apiClient.get<HotelResponse>(`${HOTELS}/${id}`);

    return data;
};

export const createHotel = async (variables: Partial<Hotel>) => {
    const { name, hotelAddress, email, notes, phoneNumber } = variables;

    const response = await apiClient.post<HotelResponse>(CREATE_HOTELS, {
        name,
        hotel_address: hotelAddress,
        email,
        notes,
        phone_number: phoneNumber,
    });

    return response;
};

export const updateHotel = async (variables: Partial<Hotel>) => {
    const { name, hotelAddress, email, notes, phoneNumber, id } = variables;

    const response = await apiClient.put<HotelResponse>(`${HOTELS}/${id}`, {
        name,
        hotel_address: hotelAddress,
        email,
        notes,
        phone_number: phoneNumber,
    });

    return response;
};

export const listHotelRoles = async (id: number): Promise<HotelRoleResponse[]> => {
    const { data } = await apiClient.get<HotelRoleResponse[]>(getHotelRolesRoute(id));

    return data;
};
