import { Alert, Form, PrimaryButton, SignedOutLayout, TertiaryButtonLink, TextField } from '@get-e/react-components';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FunctionComponent, useEffect, useRef, useState, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageTitle from '../../components/PageTitle';
import { COLORS } from '../../constans/colors';
import { STATUS_422 } from '../../constans/httpStatusCodes';
import { FORGOT_PASSWORD, BOOKINGS_PENDING } from '../../constans/urlPaths';
import { useAuth } from '../../context/AuthenticatedUserContext';
import allValid from '../../helpers/validation/allValid';
import FormError from '../../helpers/validation/FormError';
import getFormErrorMessage from '../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../helpers/validation/getHelperText';
import InputError from '../../helpers/validation/InputError';
import and from '../../helpers/validation/validators/and';
import isEmail from '../../helpers/validation/validators/isEmail';
import isFilledString from '../../helpers/validation/validators/isFilledString';

const useStyles = makeStyles({
    container: {
        padding: '2em',
        maxWidth: '320px',
        margin: '0 auto',
    },
    heading: { marginBottom: '1rem' },
    forgotPasswordContainer: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    forgotPasswordLink: {
        textDecoration: 'none',
        color: COLORS.BLUE,
        fontWeight: 600,
    },
    signInButtonContainer: { marginTop: '1rem' },
});

const SignIn: FunctionComponent<PropsWithChildren> = () => {
    const autoFocusRef = useRef<HTMLInputElement>();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<InputError | null>(null);
    const [showingPassword, setShowingPassword] = useState(false);
    const [signingIn, setSigningIn] = useState(false);

    const [formError, setFormError] = useState<FormError | 'INVALID_CREDENTIALS' | null>(null);

    const classes = useStyles();
    const navigate = useNavigate();
    const { onLogin } = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    async function submitForm() {
        const validated = {
            email: and(isFilledString(email, InputError.EmptyEmail), _value => isEmail(email, InputError.InvalidEmail)),
            password: isFilledString(password, InputError.Required),
        };

        if (!allValid(validated)) {
            setSigningIn(false);
            setEmailError(validated.email.isValid ? null : validated.email.error);
            setPasswordError(validated.password.isValid ? null : validated.password.error);
            return;
        }

        setFormError(null);
        setSigningIn(true);

        try {
            await onLogin(email, password);
            navigate(BOOKINGS_PENDING);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            setSigningIn(false);

            switch (error.response?.status) {
                case STATUS_422:
                    setFormError('INVALID_CREDENTIALS');
                    break;
                default:
                    setFormError(error.response?.data?.message);
            }
        }
    }

    const showPasswordButton = (
        <InputAdornment position="end">
            <IconButton
                aria-label={showingPassword ? 'Hide password' : 'Show password'}
                onClick={() => setShowingPassword(!showingPassword)}
                onMouseDown={event => event.preventDefault()}
            >
                {showingPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    return (
        <SignedOutLayout>
            <Paper elevation={0} className={classes.container}>
                <Form onSubmit={submitForm}>
                    <PageTitle title="Hotel bookings" />
                    <Typography variant="body1" mb={'2em'}>
                        Sign in below
                    </Typography>
                    <TextField
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                            setEmailError(null);
                        }}
                        label="Email"
                        type="email"
                        autoFocus
                        autoComplete="username"
                        name="email"
                        inputRef={autoFocusRef}
                        error={emailError !== null}
                        helperText={getHelperText(emailError, t)}
                        required
                        sx={{ paddingBottom: '2rem' }}
                    />
                    <TextField
                        label="Password"
                        type={showingPassword ? 'text' : 'password'}
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                            setPasswordError(null);
                        }}
                        autoComplete="current-password"
                        InputProps={{ endAdornment: showPasswordButton }}
                        error={passwordError !== null}
                        helperText={getHelperText(passwordError, t)}
                        required
                        sx={{ paddingBottom: '1rem' }}
                    />
                    {formError ? (
                        <Alert severity="error">
                            {formError === 'INVALID_CREDENTIALS'
                                ? 'Wrong password or user email. Try again or click Forgot password to reset it.'
                                : getFormErrorMessage(formError, t)}
                        </Alert>
                    ) : null}
                    <div className={classes.signInButtonContainer}>
                        <PrimaryButton onClick={() => submitForm()} loading={signingIn} fullWidth submitsForm>
                            Sign in
                        </PrimaryButton>
                    </div>
                    <div className={classes.forgotPasswordContainer}>
                        <TertiaryButtonLink to={FORGOT_PASSWORD}>Forgot password?</TertiaryButtonLink>
                    </div>
                </Form>
            </Paper>
        </SignedOutLayout>
    );
};

export default SignIn;
