import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

import { LOCAL_STORAGE_KEYS } from '../constans/localStorageKeys';
import useLocalStorage from '../helpers/useLocalStorage';
import { authenticateZendeskUser, logoutZendeskUser } from '../helpers/zendeskConfig';
import { User } from '../pages/people/api/types';
import { login, logout } from '../services/auth';

export interface AuthenticatedUserContextValue {
    user: User | null;
    onLogin: (email: string, password: string) => Promise<void>;
    onLogout: () => Promise<void>;
    onChangeRole: (newUser: User) => void;
}

export const AuthContext = createContext<AuthenticatedUserContextValue | null>(null);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const [storedUser, setStoredUser] = useLocalStorage<User | null>(LOCAL_STORAGE_KEYS.USER, null);

    const [user, setUser] = useState<User | null>(storedUser);

    const handleLogin = async (email: string, password: string) => {
        const userCreds = await login(email, password);

        authenticateZendeskUser();

        setUser(userCreds);
        setStoredUser(userCreds);
    };

    const handleLogout = async () => {
        try {
            await logout();
            logoutZendeskUser();
            setUser(null);
            setStoredUser(null);
            return;
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeRole = (newUser: User) => {
        setStoredUser(newUser);
    };

    const value = {
        user,
        onLogin: handleLogin,
        onLogout: handleLogout,
        onChangeRole: handleChangeRole,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthenticatedUserContextValue => {
    const authenticatedUserContext = useContext(AuthContext);

    if (authenticatedUserContext === null) {
        throw new Error('AuthenticatedUserContext should not be null');
    }

    return authenticatedUserContext;
};

export const useRoles = (): string[] => {
    const authenticatedUserContext = useContext(AuthContext);

    return authenticatedUserContext?.user?.permissions?.map(el => el?.name) ?? [];
};

export const useIsBackoffice = (): boolean => {
    const authenticatedUserContext = useContext(AuthContext);

    return authenticatedUserContext?.user?.isBackoffice ?? false;
};

export const useIsHotelUser = (): boolean => {
    const authenticatedUserContext = useContext(AuthContext);

    return Boolean(authenticatedUserContext?.user?.hotel);
};
