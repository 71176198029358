import { Modal } from '@get-e/react-components';
import { useMediaQuery } from '@mui/material';
import { FunctionComponent } from 'react';

import { Hotel } from '../accounts/api/types';
import CreateHotel from '../accounts/createAccount/CreateHotel';

interface AddNewHotelModalProps {
    onClose: () => void;
    isOpen: boolean;
    onHotelCreated: (hotel: Hotel) => void;
}

const AddNewHotelModal: FunctionComponent<AddNewHotelModalProps> = ({ onClose, isOpen, onHotelCreated }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Modal open={isOpen} onClose={onClose} fullScreen={!isMobile}>
            <CreateHotel onHotelCreated={(hotel: Hotel) => onHotelCreated(hotel)} />
        </Modal>
    );
};

export default AddNewHotelModal;
