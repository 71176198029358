import { Box, Fade, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';

import { COLORS } from '../../../constans/colors';
import { getStatusColor } from '../../../helpers/bookingStatusUtil';
import { BookingStatus } from '../api/types';

const useStyles = makeStyles({
    statusList: {
        width: '100%',
        position: 'absolute',
        top: '3em',
        right: '0',
        zIndex: '100',
        backgroundColor: COLORS.WHITE,
        borderRadius: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        padding: '.5rem 0',
    },
    circle: {
        borderRadius: '50%',
        width: '0.75rem',
        height: '0.75rem',
    },
});

interface StatusListProps {
    isListVisible: boolean;
    onItemClick: (value: keyof typeof BookingStatus) => void;
}

const StatusList = ({ isListVisible, onItemClick }: StatusListProps) => {
    const classes = useStyles();
    const [isStatusListOpen, setIsStatusListOpen] = useState(false);

    useEffect(() => {
        setIsStatusListOpen(isListVisible);
    }, [isListVisible]);

    const handleItemClick = (status: keyof typeof BookingStatus) => {
        onItemClick(status);
        setIsStatusListOpen(false);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Fade in={isStatusListOpen}>
                    <List disablePadding className={classes.statusList}>
                        {Object.keys(BookingStatus).map(value => {
                            return (
                                <ListItem key={value} sx={{ padding: '0' }}>
                                    <ListItemButton
                                        onClick={() => handleItemClick(value as keyof typeof BookingStatus)}
                                        sx={{ padding: '.875rem 1.375rem' }}
                                    >
                                        <ListItemText primary={BookingStatus[value as keyof typeof BookingStatus]} />
                                        <Box
                                            className={classes.circle}
                                            sx={{
                                                background: getStatusColor(value as keyof typeof BookingStatus),
                                            }}
                                        ></Box>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Fade>
            </Box>
        </>
    );
};

export default StatusList;
