import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

import { COLORS } from '../constans/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    label: {
        fontSize: '0.875rem',
        color: COLORS.SLATE_GREY,
        fontWeight: 700,
    },
    valueContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.25rem',
    },
    value: {
        fontSize: '1rem',
    },
    icon: {
        marginRight: '0.3rem',
        fontSize: '1rem',
        display: 'flex',
    },
}));

interface LabeledValueProps {
    label: string;
    value: string;
    iconElement?: ReactNode;
}

const LabeledValue = ({ label, value, iconElement }: LabeledValueProps) => {
    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.root}>
            <Typography className={classes.label}>{label}</Typography>
            <div className={classes.valueContainer}>
                {iconElement && <div className={classes.icon}>{iconElement}</div>}
                <Typography className={classes.value}>{value}</Typography>
            </div>
        </Grid>
    );
};

export default LabeledValue;
