import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ALL_TRAVELLERS } from '../../../constans/queryKeys';
import { listTravellers } from '../../../services/traveller';
import { Traveller, TravellerResponse, TravellersProps } from './types';

export const mapResponseToTraveller = (traveller: TravellerResponse): Traveller => ({
    id: traveller.id,
    firstName: traveller.first_name,
    lastName: traveller.last_name,
    email: traveller.email,
    mobile: traveller.phone_number,
    employeeNumber: traveller.employee_number,
});

export function useTravellers(variables: TravellersProps) {
    const { search, customerId } = variables;

    const { data, isLoading, refetch, isFetched, isFetching } = useQuery(
        [ALL_TRAVELLERS + search],
        () => listTravellers(search, customerId),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
            enabled: search.length > 2 && search !== '',
        }
    );

    return useMemo(() => {
        const travellers = data?.map(traveller => mapResponseToTraveller(traveller)) ?? [];

        return { travellers, isLoading, refetch, isFetched, isFetching };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
}
