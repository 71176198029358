import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { COLORS as MAIN_COLORS } from '../../../../constans/colors';
import { ChangeLog } from '../../api/types';

const useStyles = makeStyles({
    strikethroughText: {
        fontSize: '0.8rem',
        color: MAIN_COLORS.SLATE_GREY,
        textDecoration: 'line-through',
        paddingLeft: '0.5rem',
    },
});

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: any[];
    version: number;
    changeLogs: ChangeLog[];
}

const CustomFields = ({ fields, version, changeLogs }: Props) => {
    const classes = useStyles();

    const previousLog = changeLogs
        .find(
            el =>
                el.version < version &&
                el.details.customFields !== undefined &&
                el.details.customFields.find(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (customField: any) => customField.customFieldKey === 'is-training-booking'
                )
        )
        ?.details.customFields.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (customField: any) => customField.customFieldKey === 'is-training-booking'
        );

    const isPrevLogValue = previousLog?.value === '1' ? 'Yes' : 'No';

    return (
        <div>
            {fields.map((field, key) => {
                const isCustomFieldType = field.value === '1' ? 'Yes' : 'No';
                // Find previous log containing the modified custom field

                return (
                    <Typography key={key}>
                        <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{field.customFieldLabel} :</span>
                        {version > 1 && (
                            <span>
                                <span className={classes.strikethroughText}>
                                    {previousLog?.customFieldType === 'BOOLEAN' ? isPrevLogValue : previousLog?.value}
                                </span>
                                <span
                                    style={{
                                        paddingLeft: '0.5rem',
                                        color: MAIN_COLORS.SLATE_GREY,
                                    }}
                                >
                                    &#8594;
                                </span>
                            </span>
                        )}
                        <span style={{ paddingLeft: '0.5rem' }}>
                            {field.customFieldType === 'BOOLEAN' ? isCustomFieldType : field.value}
                        </span>
                    </Typography>
                );
            })}
        </div>
    );
};

export default CustomFields;
