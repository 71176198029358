import { useNavigate } from 'react-router';

import ErrorPage from '../../components/ErrorPage';
import { REQUESTS_PENDING } from '../../constans/urlPaths';
import { notAuthorizedIcon } from '../../images';

const ErrorNotAuthorizedPage = () => {
    const navigate = useNavigate();

    return (
        <ErrorPage
            icon={notAuthorizedIcon}
            header={'You are not authorized to see this content'}
            description={'You can find the list of your bookings by clicking on the button below.'}
            buttonTitle={'Bookings'}
            onButtonClick={() => navigate(REQUESTS_PENDING)}
        />
    );
};

export default ErrorNotAuthorizedPage;
