import { MuiTelInputCountry } from 'mui-tel-input';

export const getCurrentUserCountryCode = () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
    const { countries, zones } = require('moment-timezone/data/meta/latest.json');
    const timeZoneCityToCountry: Record<string, { abbr: MuiTelInputCountry }> = {};

    Object.keys(zones).forEach(zone => {
        const cityArr = zone.split('/');
        const city = cityArr[cityArr.length - 1];

        timeZoneCityToCountry[city] = countries[zones[zone].countries[0]];
    });

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split('/');
    const userCity = tzArr[tzArr.length - 1];

    return timeZoneCityToCountry[userCity]?.abbr;
};
