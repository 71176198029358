import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ALL_INVOICES } from '../../../constans/queryKeys';
import { listInvoices } from '../../../services/invoice';
import { InvoiceVariables } from '../types';

export const useInvoices = (variables: InvoiceVariables) => {
    const { search = '', fromDateLocal, toDateLocal, page, status } = variables;

    const { data, refetch, isLoading, isRefetching } = useQuery(
        [ALL_INVOICES + search + fromDateLocal + toDateLocal + page + status],
        () => listInvoices(variables),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
            cacheTime: 500,
        }
    );

    return useMemo(
        () => ({
            data: data ?? [],
            refetch,
            isLoading,
            isRefetching,
        }),
        [data, isLoading, isRefetching, refetch]
    );
};
