import { logAmplitudeEvent } from '../amplitude/amplitude';
import {
    BUTTON_BOOKINGS_FILTER_ALL,
    BUTTON_BOOKINGS_FILTER_ARCHIVE,
    BUTTON_BOOKINGS_FILTER_IN_PROGRESS,
    BUTTON_BOOKINGS_FILTER_PENDING,
    BUTTON_BOOKINGS_FILTER_UPCOMING,
    BUTTON_FILTER_ACCOUNTS_CUSTOMERS,
    BUTTON_FILTER_ACCOUNTS_HOTELS,
    BUTTON_REQUESTS_FILTER_ARCHIVE,
    BUTTON_REQUESTS_FILTER_IN_PROGRESS,
    BUTTON_REQUESTS_FILTER_PENDING,
    BUTTON_REQUESTS_FILTER_UPCOMING,
    BUTTON_USERS_FILTER_ACTIVE,
    BUTTON_USERS_FILTER_DEACTIVATED,
    BUTTON_USERS_FILTER_INVITED,
} from '../amplitude/amplKeys';
import { AccountsTabs } from '../pages/accounts';
import { PeopleTabs } from '../pages/people';
import { BookingTab, RequestTab } from '../pages/reservations';

export const logAmplitudeBookingTab = (tab: number): void => {
    switch (tab) {
        case BookingTab.All:
            return logAmplitudeEvent(BUTTON_BOOKINGS_FILTER_ALL);
        case BookingTab.Pending:
            return logAmplitudeEvent(BUTTON_BOOKINGS_FILTER_PENDING);
        case BookingTab.Archive:
            return logAmplitudeEvent(BUTTON_BOOKINGS_FILTER_ARCHIVE);
        case BookingTab.InProgress:
            return logAmplitudeEvent(BUTTON_BOOKINGS_FILTER_IN_PROGRESS);
        case BookingTab.Upcoming:
            return logAmplitudeEvent(BUTTON_BOOKINGS_FILTER_UPCOMING);
        default:
            return;
    }
};

export const logAmplitudeRequestTab = (tab: number): void => {
    switch (tab) {
        case RequestTab.Pending:
            return logAmplitudeEvent(BUTTON_REQUESTS_FILTER_PENDING);
        case RequestTab.Archive:
            return logAmplitudeEvent(BUTTON_REQUESTS_FILTER_ARCHIVE);
        case RequestTab.InProgress:
            return logAmplitudeEvent(BUTTON_REQUESTS_FILTER_IN_PROGRESS);
        case RequestTab.Upcoming:
            return logAmplitudeEvent(BUTTON_REQUESTS_FILTER_UPCOMING);
        default:
            return;
    }
};

export const logAmplitudeAccountsTab = (tab: number): void => {
    switch (tab) {
        case AccountsTabs.Hotels:
            return logAmplitudeEvent(BUTTON_FILTER_ACCOUNTS_HOTELS);
        case AccountsTabs.Customers:
            return logAmplitudeEvent(BUTTON_FILTER_ACCOUNTS_CUSTOMERS);
        default:
            return;
    }
};

export const logAmplitudeUsersTab = (tab: number): void => {
    switch (tab) {
        case PeopleTabs.Invited:
            return logAmplitudeEvent(BUTTON_USERS_FILTER_INVITED);
        case PeopleTabs.Active:
            return logAmplitudeEvent(BUTTON_USERS_FILTER_ACTIVE);
        case PeopleTabs.Deactivated:
            return logAmplitudeEvent(BUTTON_USERS_FILTER_DEACTIVATED);
        default:
            return;
    }
};
