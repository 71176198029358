import { COLORS } from '../constans/colors';
import { BookingStatus } from '../pages/reservations/api/types';

export const BookingStatusColor = {
    SEARCHING_ROOMS: COLORS.ORANGE,
    BOOKING_PENDING: COLORS.ORANGE,
    BOOKING_UPDATE_PENDING: COLORS.ORANGE,
    BOOKING_CONFIRMED: COLORS.GREEN,
    BOOKING_CANCELLED: COLORS.RED,
    PROCESS_CANCELLATION: COLORS.ORANGE,
    NOT_AVAILABLE: COLORS.DARK_GRAY,
    COMPLETED: COLORS.BLUE,
} as {
    [key in keyof typeof BookingStatus]: keyof typeof COLORS;
};

export const BookingStatusButtonColor = {
    SEARCHING_ROOMS: COLORS.LIGHT_ORANGE,
    BOOKING_PENDING: COLORS.LIGHT_ORANGE,
    BOOKING_UPDATE_PENDING: COLORS.LIGHT_ORANGE,
    BOOKING_CONFIRMED: COLORS.LIGHT_GREEN,
    BOOKING_CANCELLED: COLORS.LIGHT_RED,
    PROCESS_CANCELLATION: COLORS.LIGHT_ORANGE,
    NOT_AVAILABLE: COLORS.LIGHT_GRAY,
    COMPLETED: COLORS.LIGHT_BLUE,
} as {
    [key in keyof typeof BookingStatus]: keyof typeof COLORS;
};

export const BookingStatusTextColor = {
    SEARCHING_ROOMS: COLORS.ORANGE_TEXT,
    BOOKING_PENDING: COLORS.ORANGE_TEXT,
    BOOKING_UPDATE_PENDING: COLORS.ORANGE_TEXT,
    BOOKING_CONFIRMED: COLORS.GREEN_TEXT,
    BOOKING_CANCELLED: COLORS.RED,
    PROCESS_CANCELLATION: COLORS.ORANGE_TEXT,
    NOT_AVAILABLE: COLORS.BLACK,
    COMPLETED: COLORS.BLUE_TEXT,
} as {
    [key in keyof typeof BookingStatus]: keyof typeof COLORS;
};

export const BookingStatusHoverColor = {
    SEARCHING_ROOMS: COLORS.MEDIUM_ORANGE,
    BOOKING_PENDING: COLORS.MEDIUM_ORANGE,
    BOOKING_UPDATE_PENDING: COLORS.MEDIUM_ORANGE,
    BOOKING_CONFIRMED: COLORS.LIGHT_GREEN,
    BOOKING_CANCELLED: COLORS.LIGHT_RED,
    PROCESS_CANCELLATION: COLORS.MEDIUM_ORANGE,
    NOT_AVAILABLE: COLORS.DARK_GRAY,
    COMPLETED: COLORS.LIGHT_BLUE,
} as {
    [key in keyof typeof BookingStatus]: keyof typeof COLORS;
};

export const getStatusColor = (status: keyof typeof BookingStatus) => BookingStatusColor[status];

export const getStatusButtonColor = (status: keyof typeof BookingStatus) => BookingStatusButtonColor[status];

export const getStatusTextColor = (status: keyof typeof BookingStatus) => BookingStatusTextColor[status];

export const getStatusHoverColor = (status: keyof typeof BookingStatus) => BookingStatusHoverColor[status];

export const getBookingStatusKeyName = (value: string): keyof typeof BookingStatus => {
    return Object.entries(BookingStatus).find(([_, val]) => val === value)?.[0] as keyof typeof BookingStatus;
};
