import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ALL_HOTELS } from '../../../constans/queryKeys';
import { listHotels } from '../../../services/hotel';
import { Hotel, HotelResponse } from './types';

export function useHotels(search = '') {
    const { data, isLoading, isError } = useQuery([ALL_HOTELS + search], () => listHotels(search), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    const mapHotelToAccount = (hotel: HotelResponse): Hotel => ({
        id: hotel.id,
        name: hotel.name,
        hotelAddress: hotel.hotel_address?.full_address,
        phoneNumber: hotel?.phone_number,
        email: hotel?.email,
        notes: hotel?.notes,
        timeZone: hotel?.hotel_address?.time_zone,
    });

    return useMemo(() => {
        const hotels = data?.map((hotel: HotelResponse) => mapHotelToAccount(hotel)) ?? [];

        return { hotels, isLoading, isError };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError]);
}
