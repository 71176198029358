import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ALL_HOTELS_NEARBY } from '../../../constans/queryKeys';
import { listHotelsNearby } from '../../../services/hotel';
import { Hotel, HotelResponse, HotelsNearbyProps } from './types';

export const mapHotelToAccount = (hotel: HotelResponse): Hotel => ({
    id: hotel.id,
    name: hotel.name,
    hotelAddress: hotel.hotel_address?.full_address,
    phoneNumber: hotel?.phone_number,
    email: hotel?.email,
    notes: hotel?.notes,
    distance_km: hotel?.distance_km,
});

export function useHotelsNearby({ lat, long, search, isEnabled }: HotelsNearbyProps) {
    const { data, isLoading, isError, refetch, isFetched } = useQuery(
        [ALL_HOTELS_NEARBY + search],
        () => listHotelsNearby(lat, long, search),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: isEnabled ?? (Boolean(lat) && Boolean(long)),
        }
    );

    return useMemo(() => {
        const hotels = data?.map((hotel: HotelResponse) => mapHotelToAccount(hotel)) ?? [];

        return { hotels, isLoading, isError, refetch, isFetched };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError]);
}
