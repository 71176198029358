import { Spinner, TextField } from '@get-e/react-components';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment, StandardTextFieldProps, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DateRange, DateRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

import { COLORS } from '../../constans/colors';
import { useAuth, useIsBackoffice } from '../../context/AuthenticatedUserContext';
import { DATE_FORMATS } from '../../helpers/dateFormats';
import { dateTimeRangePickerStyles, dayStyles, textFieldStyles } from '../../styles/DateTimeRangePicker';
import { useDataGridStyles } from '../accounts/styles/useDataGridStyles';
import downloadStatement from './api/downloadStatement';
import { useStatements } from './api/useStatements';
import { useStatementsColumns } from './hooks/useStatementsColumns';

let searchTimeoutId: ReturnType<typeof setTimeout> | number = -1;

const Statements = () => {
    const [searchPhrase, setSearchPhrase] = useState('');
    const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Moment>>([null, null]);

    const [page, setPage] = useState(0);
    const dataGridClasses = useDataGridStyles();
    const { user } = useAuth();
    const isBackoffice = useIsBackoffice();
    const isHotelUser = Boolean(user?.hotel);
    const isCustomerUser = Boolean(user?.customer);

    const {
        data: { data: results, meta },
        isLoading,
        isRefetching,
    } = useStatements({
        search: debouncedSearchPhrase,
        fromDateLocal: selectedDateRange?.includes(null)
            ? undefined
            : moment(selectedDateRange[0]).format(DATE_FORMATS['YYYY-MM-DD']),
        toDateLocal: selectedDateRange?.includes(null)
            ? undefined
            : moment(selectedDateRange[1]).format(DATE_FORMATS['YYYY-MM-DD']),
        page,
    });

    const { mutate: downloadStatementMutation } = useMutation(downloadStatement);

    const handleSearchInputOnChange = useCallback(
        (value: string) => {
            setSearchPhrase(value);
            clearTimeout(searchTimeoutId);
            searchTimeoutId = setTimeout(() => {
                setDebouncedSearchPhrase(value);
            }, 600);
        },
        [setDebouncedSearchPhrase]
    );

    const handleDownloadStatement = (id: number) => {
        downloadStatementMutation(id);
    };

    const columns = useStatementsColumns(handleDownloadStatement);

    return (
        <Grid container alignItems="flex-start">
            <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                    <Typography
                        sx={{
                            color: COLORS.BLUE,
                            fontSize: '1.5rem',
                            fontWeight: 700,
                        }}
                    >
                        Statements
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Grid item xs={12} md={8} xl={8}>
                    <TextField
                        style={{ marginBottom: 0 }}
                        type="text"
                        autoComplete="off"
                        value={searchPhrase}
                        noHelperTextSpace
                        onChange={event => {
                            handleSearchInputOnChange(event.target.value);
                        }}
                        label="Search by hotel or customer name"
                        name="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading || isRefetching ? <Spinner size={16} /> : <SearchIcon />}
                                </InputAdornment>
                            ),
                            onBlur: event => setSearchPhrase(event.target.value),
                        }}
                        onBlur={event => setSearchPhrase(event.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    xl={4}
                    sx={{
                        paddingLeft: ['0', '0', '2rem'],
                        marginTop: ['1rem', '1rem', 0],
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <DateRangePicker
                        sx={dateTimeRangePickerStyles}
                        value={selectedDateRange}
                        label={'Date range'}
                        onChange={newValue => setSelectedDateRange(newValue)}
                        slotProps={{
                            textField: { ...(textFieldStyles as StandardTextFieldProps) },
                            field: { clearable: true },
                            day: {
                                sx: dayStyles,
                            },
                        }}
                        slots={{ field: SingleInputDateTimeRangeField }}
                        format={DATE_FORMATS['DD MMM YYYY']}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ height: '400px' }}>
                <DataGrid
                    className={dataGridClasses.dataGrid}
                    disableColumnSelector
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={results ?? []}
                    columns={columns as GridColDef[]}
                    pagination
                    page={page}
                    pageSize={meta?.per_page}
                    paginationMode="server"
                    onPageChange={newPage => setPage(newPage)}
                    rowCount={meta?.total ?? 0}
                    loading={isLoading || isRefetching}
                    columnVisibilityModel={{
                        hotelName: isCustomerUser || isBackoffice,
                        customerName: isHotelUser || isBackoffice,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Statements;
