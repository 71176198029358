/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { Autocomplete, PrimaryButton, Spinner, TextField } from '@get-e/react-components';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    ClickAwayListener,
    FormControlLabel,
    Grid,
    Stack,
    StandardTextFieldProps,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { DateRange, DateRangePicker, TimePicker } from '@mui/x-date-pickers-pro';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching, useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import {
    BUTTON_CREATE_UPDATE_BOOKING_STATUS,
    BUTTON_INCLUDE_BREAKFAST,
    BUTTON_INCLUDE_PARKING,
    BUTTON_MAIL_TO_BOOKER_CONFIRMED,
    BUTTON_MAIL_TO_BOOKER_UPDATED,
    BUTTON_MAIL_TO_GUEST_CONFIRMED,
    BUTTON_MAIL_TO_GUEST_UPDATED,
    BUTTON_SAVE_BOOKING,
    INPUT_BOOKING_CONFIRMATION_NUMBER,
    INPUT_CHECKIN_TIME,
    INPUT_CHECKINOUT_DATE,
    INPUT_CHECKOUT_TIME,
    INPUT_HOTEL_NAME,
    INPUT_SPECIAL_REQUEST,
    SCREEN_CREATE_EDIT_BOOKING,
} from '../../../amplitude/amplKeys';
import GoogleMap, { Point } from '../../../components/GoogleMap';
import LabeledData from '../../../components/LabeledData';
import PageTitle from '../../../components/PageTitle';
import { COLORS } from '../../../constans/colors';
import * as emailLabels from '../../../constans/emailStatuses';
import { ALL_HOTELS_NEARBY } from '../../../constans/queryKeys';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { getBookingStatusKeyName } from '../../../helpers/bookingStatusUtil';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { DEFAULT_COORDINATES } from '../../../helpers/maps/googleApi';
import { TIME_FORMATS } from '../../../helpers/timeFormats';
import allValid from '../../../helpers/validation/allValid';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import { Validated } from '../../../helpers/validation/Validator';
import and from '../../../helpers/validation/validators/and';
import isAfterTime from '../../../helpers/validation/validators/isAfterTime';
import isNotNull from '../../../helpers/validation/validators/isNotNull';
import { createBooking } from '../../../services/booking';
import { dayStyles, textFieldStyles } from '../../../styles/DateTimeRangePicker';
import { Hotel } from '../../accounts/api/types';
import { useHotelsNearby } from '../../accounts/api/useHotelsNearby';
import { DEAFULT_ADD_HOTEL_BUTTON_OPTION, NO_RESULTS_HOTEL_OPTION } from '../../hotels/api/constants';
import { useBenefits } from '../../hotels/api/useBenefits';
import AddNewHotelModal from '../AddNewHotelModal';
import { Benefits, Request, BookingStatus } from '../api/types';
import SaveBookingModal from '../components/SaveBookingModal';
import StatusButton from '../components/StatusButton';
import StatusList from '../components/StatusList';
import { getRoundedDistance } from '../helpers/getRoundedDistance';

const useStyles = makeStyles(() => ({
    saveButton: {
        width: '150px',
    },
    bookingStatus: {
        lineHeight: '0.5rem',
    },
    statusList: {
        width: '100%',
        position: 'absolute',
        top: '3em',
        right: '0',
        zIndex: '100',
        backgroundColor: COLORS.WHITE,
        borderRadius: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
    noResults: {
        textAlign: 'center',
        padding: '.75rem 1rem',
    },
    addNewContainer: {
        borderTop: `1px solid ${COLORS.DARK_GRAY}`,
        padding: '.75rem 1rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
    },
    boxItem: {
        padding: '.75rem 1rem',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
    },
    secondaryText: {
        color: COLORS.DARK_GRAY,
    },
    searchLoader: {
        position: 'absolute',
        right: '40px',
        top: '45%',
        zIndex: '100',
    },
    map: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '220px',
        padding: '0 !important',
    },
}));

interface ValidationObject {
    hotel: Validated<Hotel | null, InputError>;
    checkInOutDate: Validated<Moment, InputError>;
    checkInTime?: Validated<Moment, InputError>;
    checkOutTime?: Validated<Date, InputError>;
}

interface CreateBookingProps {
    request: Request;
}

const CreateBooking = ({ request }: CreateBookingProps) => {
    const [isStatusListOpen, setIsStatusListOpen] = useState(false);
    const [bookingStatus, setbookingStatus] = useState(request.status);

    const checkInTimeInitial = request.is_time_included ? moment(request.check_in_date_local) : null;

    const checkOutTimeInitial = request.is_time_included ? moment(request.check_out_date_local) : null;

    const [bookingConfirmationNumber, setBookingConfirmationNumber] = useState<string>('');

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Moment>>([
        moment(request.check_in_date_local),
        moment(request.check_out_date_local),
    ]);

    const [checkInTime, setCheckInTime] = useState<Moment | null>(checkInTimeInitial);
    const [checkOutTime, setCheckOutTime] = useState<Moment | null>(checkOutTimeInitial);
    const [openInTime, setOpenInTime] = useState(false);
    const [openOutTime, setOpenOutTime] = useState(false);

    const [comment, setComment] = useState(request.comments);

    const [includeBreakfast, setIncludeBreakfast] = useState(
        request.benefits?.some(benefit => benefit.name === Benefits.INCLUDE_BREAKFAST)
    );

    const [includeParking, setIncludeParking] = useState(
        request.benefits?.some(benefit => benefit.name === Benefits.INCLUDE_PARKING)
    );

    const [isTimeIncluded, setIsTimeIncluded] = useState(false);
    const [hotelIdError, setHotelIdError] = useState<InputError | null>(null);

    const [dateRangeError, setDateRangeError] = useState<InputError | null>(null);
    const [checkInTimeError, setCheckInTimeError] = useState<InputError | null>(null);
    const [checkOutTimeError, setCheckOutTimeError] = useState<InputError | null>(null);

    const [inputValue, setInputValue] = useState('');
    const [isOpenAddHotelModal, setIsOpenAddHotelModal] = useState(false);
    const [isOpenSaveBookingModal, setIsOpenSaveBookingModal] = useState(false);
    const [hotel, setHotel] = useState<Hotel | null>(null);

    const [sendEmail, setSendEmail] = useState({
        requestedToHotel: true,
        updatedToHotel: true,
        updatedToBooker: false,
        confirmedToBooker: true,
        updatedToTraveller: false,
        confirmedToTraveller: true,
    });

    const { requestedToHotel, updatedToHotel, updatedToBooker, confirmedToBooker, updatedToTraveller, confirmedToTraveller } =
        sendEmail;

    const { showNotification } = useNotificationContext();
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const classes = useStyles();
    const { t } = useTranslation();

    const { benefits } = useBenefits();

    const {
        hotels,
        isLoading: isLoadingHotels,
        refetch: refetchHotels,
    } = useHotelsNearby({
        lat: request.request_address.lat,
        long: request.request_address.long,
        search: inputValue,
    });

    const isFetchingHotels = Boolean(useIsFetching([ALL_HOTELS_NEARBY + inputValue]));

    const hotelsOptions: Hotel[] = useMemo(() => {
        return [...hotels, DEAFULT_ADD_HOTEL_BUTTON_OPTION];
    }, [hotels]);

    useEffect(() => {
        logAmplitudeEvent(SCREEN_CREATE_EDIT_BOOKING);
    }, []);

    useEffect(() => {
        setIsTimeIncluded(Boolean(checkInTime) || Boolean(checkOutTime));
    }, [checkInTime, checkOutTime]);

    useEffect(() => {
        if (!isOpenAddHotelModal && Boolean(inputValue)) {
            refetchHotels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenAddHotelModal]);

    useEffect(() => {
        setSendEmail({
            ...sendEmail,
            requestedToHotel: true,
            updatedToHotel: true,
            updatedToBooker: false,
            confirmedToBooker: true,
            updatedToTraveller: false,
            confirmedToTraveller: true,
        });
    }, [bookingStatus]);

    const guest = useMemo(() => {
        return request?.guests[0];
    }, [request?.guests]);

    const { mutate: createBookingMutation, isLoading: isLoadingCreate } = useMutation(createBooking, {
        onSuccess: () => {
            showNotification('Booking successfully created.', Severity.Info);
            navigate(-1);
        },
        onError: (error: AxiosError<Error>) => {
            showNotification(error.response?.data.message ?? t('errors.retry'), Severity.Error);
        },
    });

    const findBenefitId = (benefitName: Benefits): number => {
        const id = benefits?.find(benefit => benefit.name === benefitName)?.id;

        return id ? parseInt(id, 10) : 0;
    };

    const getBenefitIds = (): number[] => {
        const benefitIds: number[] = [];

        if (includeBreakfast) {
            const benefitId = findBenefitId(Benefits.INCLUDE_BREAKFAST);

            if (benefitId) {
                benefitIds.push(benefitId);
            }
        }

        if (includeParking) {
            const benefitId = findBenefitId(Benefits.INCLUDE_PARKING);

            if (benefitId) {
                benefitIds.push(benefitId);
            }
        }

        return benefitIds;
    };

    const validateFormFields = (): boolean => {
        setCheckInTimeError(null);
        setCheckOutTimeError(null);

        const validated: ValidationObject = {
            hotel: isNotNull(hotel, InputError.Required),
            checkInOutDate: and(isNotNull(selectedDateRange?.[0], InputError.Required), () =>
                isNotNull(selectedDateRange?.[1], InputError.Required)
            ),
        };

        const isSameDate =
            selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']) ===
            selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']);

        if (isTimeIncluded) {
            validated.checkInTime = isNotNull(checkInTime, InputError.Required);
            validated.checkOutTime = and(isNotNull(checkOutTime, InputError.Required), () =>
                isSameDate
                    ? isAfterTime(checkOutTime?.toDate() || null, checkInTime?.toDate() || null, InputError.InvalidAfterTime)
                    : ({
                          isValid: true,
                          value: new Date(),
                      } as Validated<Date, InputError>)
            );
        }

        if (!allValid(validated)) {
            setHotelIdError(validated.hotel.isValid ? null : validated.hotel.error);
            setDateRangeError(validated.checkInOutDate.isValid ? null : InputError.Required);

            if (isTimeIncluded) {
                setCheckInTimeError(validated.checkInTime?.isValid ? null : validated.checkInTime?.error || null);
                setCheckOutTimeError(validated.checkOutTime?.isValid ? null : validated.checkOutTime?.error || null);
            }

            return false;
        }

        return true;
    };

    const isEmailing = updatedToHotel || updatedToTraveller || updatedToBooker;

    const handleSave = () => {
        logAmplitudeEvent(BUTTON_SAVE_BOOKING);

        if (getBookingStatusKeyName(bookingStatus) === getBookingStatusKeyName(BookingStatus.BOOKING_CANCELLED) || isEmailing) {
            validateAndConfirm();
        } else {
            validateAndSaveBooking();
        }
    };

    const validateAndConfirm = () => {
        if (validateFormFields()) {
            setIsOpenSaveBookingModal(true);
        }
    };

    const validateAndSaveBooking = () => {
        if (validateFormFields()) {
            createBookingMutation({
                requestId: request.id,
                status: getBookingStatusKeyName(bookingStatus),
                hotelId: hotel?.id ?? 0,
                checkInDate: selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']) || '',
                checkOutDate: selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']) || '',
                isTimeIncluded,
                checkInTime: isTimeIncluded ? checkInTime?.format(TIME_FORMATS['HH:mm:ss']) || '' : null,
                checkOutTime: isTimeIncluded ? checkOutTime?.format(TIME_FORMATS['HH:mm:ss']) || '' : null,
                notes: comment,
                bookingNumber: bookingConfirmationNumber,
                guestId: guest.id,
                sendEmailRequestedToHotel: bookingStatus === BookingStatus.BOOKING_PENDING ? requestedToHotel : false,
                sendEmailUpdatedToHotel: bookingStatus === BookingStatus.BOOKING_UPDATE_PENDING ? updatedToHotel : false,
                sendEmailCancelledToHotel: false,
                sendEmailUpdatedToBooker: false,
                sendEmailCancelledToBooker: false,
                sendEmailConfirmedToBooker: bookingStatus === BookingStatus.BOOKING_CONFIRMED ? confirmedToBooker : false,
                sendEmailUpdatedToTraveller: false,
                sendEmailCancelledToTraveller: false,
                sendEmailConfirmedToTraveller: bookingStatus === BookingStatus.BOOKING_CONFIRMED ? confirmedToTraveller : false,
                benefitIds: getBenefitIds(),
            });
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const toggleStatusList = () => {
        setIsStatusListOpen(prev => !prev);
    };

    const handleStatusItemClick = (status: keyof typeof BookingStatus) => {
        setbookingStatus(BookingStatus[status]);
        setIsStatusListOpen(false);
        logAmplitudeEvent(BUTTON_CREATE_UPDATE_BOOKING_STATUS);
    };

    const renderAddNewButton = () => {
        return (
            <Box component="li" className={classes.addNewContainer} onClick={() => setIsOpenAddHotelModal(true)}>
                + Add new
            </Box>
        );
    };

    const handleClickAway = () => {
        setIsStatusListOpen(false);
    };

    const handleConfirmSaveBooking = () => {
        validateAndSaveBooking();
    };

    const handleCheckInTime = (newCheckInTime: Moment | null) => {
        setCheckInTime(newCheckInTime);
        setCheckInTimeError(null);
        fixCheckOutTime(newCheckInTime);
    };

    const fixCheckOutTime = (newCheckInTime: Moment | null) => {
        if (
            moment(selectedDateRange?.[0]).format(DATE_FORMATS['YYYY-MM-DD']) ===
            moment(selectedDateRange?.[1]).format(DATE_FORMATS['YYYY-MM-DD'])
        ) {
            if (moment(newCheckInTime).hour() > moment(checkOutTime).hour()) {
                setCheckOutTime(moment(newCheckInTime).add(1, 'hours'));
            }

            if (
                moment(newCheckInTime).hour() === moment(checkOutTime).hour() &&
                moment(newCheckInTime).minute() > moment(checkOutTime).minute()
            ) {
                setCheckOutTime(moment(newCheckInTime).add(30, 'minutes'));
            }
        }
    };

    const handleHotelCreated = (hotelCreated: Hotel) => {
        setHotel(hotelCreated);
        setIsOpenAddHotelModal(false);
    };

    const points = useMemo(() => {
        return [
            {
                latitude: parseFloat(request.request_address.lat ?? DEFAULT_COORDINATES.lat.toString()),
                longitude: parseFloat(request.request_address.long ?? DEFAULT_COORDINATES.long.toString()),
            } as Point,
        ];
    }, [request.request_address]);

    const driverPosition = useMemo(() => {
        return {
            timestamp: moment(),
            coordinates: {
                latitude: parseFloat(request.request_address.lat ?? DEFAULT_COORDINATES.lat.toString()),
                longitude: parseFloat(request.request_address.long ?? DEFAULT_COORDINATES.long.toString()),
            },
            coordinatesAccuracyMeters: 100,
            bearingDegrees: 0,
        };
    }, [request.request_address]);

    return (
        <>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '1.5rem' }}>
                <Grid item xs={12} md={8} xl={8}>
                    <PageTitle title={`Create booking nearby ${request.request_address.short_name}`} />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6} xl={6}>
                    <Grid item xs={12} md={12} xl={12} sx={{ marginBottom: '2rem' }}>
                        <Card variant="outlined">
                            <CardContent className={classes.map}>
                                {isFetchingHotels && <Spinner size={50} />}
                                {!isFetchingHotels && points && <GoogleMap points={points} driverPosition={driverPosition} />}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container columnSpacing={1} rowSpacing={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <LabeledData label="Booking information" labelColumnNumber={12} labelColor={COLORS.BLUE} />
                                </Grid>
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        textAlign={isMobile ? 'left' : 'right'}
                                        sx={{
                                            position: 'relative',
                                            paddingTop: isMobile ? '0 !important' : '1rem',
                                        }}
                                    >
                                        {request?.status && (
                                            <>
                                                <StatusButton
                                                    className={classes.bookingStatus}
                                                    variant={bookingStatus}
                                                    onClick={toggleStatusList}
                                                    showDropdownButton
                                                    disablePulseEffect={false}
                                                >
                                                    {bookingStatus}
                                                </StatusButton>

                                                <StatusList
                                                    isListVisible={isStatusListOpen}
                                                    onItemClick={handleStatusItemClick}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                </ClickAwayListener>
                                <Grid item xs={12} sx={{ position: 'relative', gridArea: 'gridItem2' }}>
                                    {isFetchingHotels && <Spinner className={classes.searchLoader} size={25} />}
                                    <Autocomplete
                                        required
                                        filterOptions={options => {
                                            if (options.length === 1) {
                                                options.unshift(NO_RESULTS_HOTEL_OPTION);
                                            }

                                            return options;
                                        }}
                                        isError={hotelIdError !== null}
                                        helperText={getHelperText(hotelIdError, t)}
                                        label="Hotel name"
                                        loading={isLoadingHotels}
                                        options={hotelsOptions}
                                        getOptionLabel={(option: Hotel) => option.name}
                                        renderOption={(prop, option: Hotel) => {
                                            if (option.id === 'NO_RESULTS') {
                                                return (
                                                    <Box
                                                        component="li"
                                                        {...prop}
                                                        className={classes.noResults}
                                                        onClick={() => {}}
                                                    >
                                                        No results found
                                                    </Box>
                                                );
                                            }

                                            if (option.id === 'ADD') {
                                                return <>{renderAddNewButton()}</>;
                                            }

                                            return (
                                                <Box
                                                    component="li"
                                                    {...prop}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                    }}
                                                    className={classes.boxItem}
                                                >
                                                    <Typography sx={{ marginRight: '1rem' }}>
                                                        {option.name}{' '}
                                                        <span
                                                            style={{
                                                                color: COLORS.DARK_GRAY,
                                                            }}
                                                        >
                                                            {getRoundedDistance(option?.distance_km ?? -1)}
                                                        </span>
                                                    </Typography>
                                                    <Typography className={classes.secondaryText}>
                                                        {/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
                                                        {option?.hotelAddress.toString()}
                                                    </Typography>
                                                </Box>
                                            );
                                        }}
                                        value={hotel}
                                        onChange={(_, newValue) => {
                                            setHotel(newValue ?? null);
                                            setHotelIdError(null);
                                            logAmplitudeEvent(INPUT_HOTEL_NAME);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        noOptionsText={renderAddNewButton()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Booking confirmation number"
                                        value={bookingConfirmationNumber}
                                        onChange={event => setBookingConfirmationNumber(event.target.value)}
                                        required={bookingStatus === 'Confirmed'}
                                        onFocus={() => logAmplitudeEvent(INPUT_BOOKING_CONFIRMATION_NUMBER)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DateRangePicker
                                        localeText={{
                                            start: 'Check-in',
                                            end: 'Check-out',
                                        }}
                                        value={selectedDateRange}
                                        onChange={newValue => {
                                            setSelectedDateRange(newValue);
                                            setDateRangeError(null);
                                            logAmplitudeEvent(INPUT_CHECKINOUT_DATE);
                                        }}
                                        format={DATE_FORMATS['DD MMM YYYY']}
                                        slotProps={{
                                            field: { dateSeparator: 'to' },
                                            textField: {
                                                ...(textFieldStyles as StandardTextFieldProps),
                                                required: true,
                                                error: dateRangeError !== null,
                                                helperText: getHelperText(dateRangeError, t),
                                            },
                                            day: {
                                                sx: dayStyles,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TimePicker
                                        label="Check-in time"
                                        value={checkInTime}
                                        onChange={newValue => handleCheckInTime(newValue)}
                                        format={TIME_FORMATS['HH:mm A']}
                                        onAccept={() => setOpenInTime(true)}
                                        minutesStep={15}
                                        onClose={() => {
                                            logAmplitudeEvent(INPUT_CHECKIN_TIME);
                                            setOpenInTime(false);
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: checkInTimeError !== null,
                                                helperText: getHelperText(checkInTimeError, t),
                                                variant: 'filled',
                                                inputProps: { readOnly: true },
                                                onClick: () => setOpenInTime(true),
                                            },
                                            field: { clearable: true },
                                        }}
                                        open={openInTime}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography></Typography>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <TimePicker
                                        label="Check-out time"
                                        value={checkOutTime}
                                        onChange={newValue => {
                                            setCheckOutTime(newValue);
                                            setCheckOutTimeError(null);
                                        }}
                                        format={TIME_FORMATS['HH:mm A']}
                                        onAccept={() => setOpenOutTime(true)}
                                        minutesStep={15}
                                        onClose={() => {
                                            logAmplitudeEvent(INPUT_CHECKOUT_TIME);
                                            setOpenOutTime(false);
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: checkOutTimeError !== null,
                                                helperText: getHelperText(checkOutTimeError, t),
                                                variant: 'filled',
                                                inputProps: { readOnly: true },
                                                onClick: () => setOpenOutTime(true),
                                            },
                                            field: { clearable: true },
                                        }}
                                        open={openOutTime}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Special requests (optional)"
                                        value={comment}
                                        onChange={event => setComment(event.target.value)}
                                        multiline
                                        rows={4}
                                        onFocus={() => logAmplitudeEvent(INPUT_SPECIAL_REQUEST)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={includeBreakfast}
                                                onChange={event => setIncludeBreakfast(event.target.checked)}
                                                onFocus={() => logAmplitudeEvent(BUTTON_INCLUDE_BREAKFAST)}
                                            />
                                        }
                                        label="Include breakfast"
                                        sx={{ width: '100%' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={includeParking}
                                                onChange={event => setIncludeParking(event.target.checked)}
                                                onFocus={() => logAmplitudeEvent(BUTTON_INCLUDE_PARKING)}
                                            />
                                        }
                                        label="Include parking"
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container columnSpacing={1} rowSpacing={2}>
                                <Grid item xs={12}>
                                    <LabeledData label="Traveller" labelColor={COLORS.BLUE} />
                                    <LabeledData
                                        label="Name"
                                        labelColor={COLORS.BLACK}
                                        data={`${guest?.first_name ?? ''} ${guest?.last_name ?? ''}`}
                                        dataSuffix={guest?.traveller?.employee_number?.toString() ?? ''}
                                    />
                                    <LabeledData
                                        label="Phone number"
                                        labelColor={COLORS.BLACK}
                                        data={`${guest?.phone_number ?? ''}`}
                                    />
                                    <LabeledData label="Email" labelColor={COLORS.BLACK} data={`${guest?.email ?? ''}`} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid item sx={{ marginTop: '1rem' }}>
                        {bookingStatus === BookingStatus.BOOKING_PENDING && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sendEmail.requestedToHotel}
                                        onChange={event =>
                                            setSendEmail({
                                                ...sendEmail,
                                                requestedToHotel: event.target.checked,
                                            })
                                        }
                                    />
                                }
                                label={
                                    <Typography display="inline-flex">
                                        {emailLabels.SEND_EMAIL_TO_HOTEL}
                                        <Typography color={COLORS.SLATE_GREY}>
                                            <b>&nbsp;{emailLabels.SUBJECT}</b>
                                            {emailLabels.BOOKING_REQUESTED}
                                        </Typography>
                                    </Typography>
                                }
                                value={sendEmail.requestedToHotel}
                                sx={{ width: '100%' }}
                            />
                        )}
                        {bookingStatus === BookingStatus.BOOKING_CONFIRMED && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={confirmedToBooker}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    confirmedToBooker: event.target.checked,
                                                })
                                            }
                                            onFocus={() => logAmplitudeEvent(BUTTON_MAIL_TO_BOOKER_CONFIRMED)}
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_BOOKER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_CONFIRMED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={confirmedToBooker}
                                    sx={{ width: '100%' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={updatedToBooker}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    updatedToBooker: event.target.checked,
                                                })
                                            }
                                            onFocus={() => logAmplitudeEvent(BUTTON_MAIL_TO_BOOKER_UPDATED)}
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_BOOKER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_UPDATED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={updatedToBooker}
                                    sx={{ width: '100%' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={confirmedToTraveller}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    confirmedToTraveller: event.target.checked,
                                                })
                                            }
                                            onFocus={() => logAmplitudeEvent(BUTTON_MAIL_TO_GUEST_CONFIRMED)}
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_TRAVELLER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_CONFIRMED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={confirmedToTraveller}
                                    sx={{ width: '100%' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={updatedToTraveller}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    updatedToTraveller: event.target.checked,
                                                })
                                            }
                                            onFocus={() => logAmplitudeEvent(BUTTON_MAIL_TO_GUEST_UPDATED)}
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_TRAVELLER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_UPDATED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={updatedToTraveller}
                                    sx={{ width: '100%' }}
                                />
                            </>
                        )}
                        {bookingStatus !== BookingStatus.BOOKING_CONFIRMED && bookingStatus !== BookingStatus.BOOKING_PENDING && (
                            <Typography>No message to be sent.</Typography>
                        )}
                    </Grid>
                    <Stack spacing={2} direction="row" sx={{ marginTop: '1rem' }}>
                        <PrimaryButton className={classes.saveButton} onClick={handleSave} loading={isLoadingCreate}>
                            Save
                        </PrimaryButton>
                        <Button
                            variant="outlined"
                            onClick={handleBack}
                            sx={{
                                backgroundColor: COLORS.WHITE,
                                color: COLORS.BLACK,
                                width: '150px !important',
                                borderColor: COLORS.BLACK,
                            }}
                        >
                            Back
                        </Button>
                    </Stack>
                    {isOpenAddHotelModal && (
                        <AddNewHotelModal
                            isOpen={isOpenAddHotelModal}
                            onClose={() => {
                                setIsOpenAddHotelModal(false);
                            }}
                            onHotelCreated={handleHotelCreated}
                        />
                    )}
                    {isOpenSaveBookingModal && (
                        <SaveBookingModal
                            title="Add booking"
                            isCancelling={
                                getBookingStatusKeyName(bookingStatus) ===
                                getBookingStatusKeyName(BookingStatus.BOOKING_CANCELLED)
                            }
                            isSendingEmails={isEmailing}
                            onClose={() => {
                                setIsOpenSaveBookingModal(false);
                            }}
                            onConfirm={handleConfirmSaveBooking}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default CreateBooking;
