import { Validated } from '../Validator';

const isFilledObjectAttributes = <TValue extends object, TError>(
    value: TValue | null,
    error: TError
): Validated<TValue, TError> =>
    value === null || Object.values(value).every(attr => attr === null || attr === '')
        ? {
              isValid: false,
              error,
          }
        : {
              isValid: true,
              value,
          };

export default isFilledObjectAttributes;
