import { useQuery } from 'react-query';

import { searchOptionsForBooking } from '../../../services/booking';
import { OPTION_SEARCH_FOR_BOOKINGS } from '../../../services/routes';

export const useSearchOptionsForBooking = (search: string) => {
    return useQuery([OPTION_SEARCH_FOR_BOOKINGS + search], () => searchOptionsForBooking(search), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: 1,
        cacheTime: 500,
        enabled: search !== '',
    });
};
