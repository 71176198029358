import { Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import SimpleLink from '../../components/SimpleLink';
import { BREADCRUMB_DIVIDER } from '../../constans/placeholders';
import { BOOKING_ID_PREFIX, REQUEST_ID_PREFIX } from '../../constans/prefixes';
import {
    CREATE_REQUEST,
    CREATE_BOOKING,
    EDIT_REQUEST,
    EDIT_BOOKING,
    REQUESTS_ARCHIVE,
    REQUESTS_PENDING,
    REQUESTS_UPCOMING,
    VIEW_REQUESTS,
    REQUESTS,
    BOOKINGS_PENDING,
    BOOKINGS,
    BOOKINGS_UPCOMING,
    BOOKINGS_IN_PROGRESS,
    BOOKINGS_ARCHIVE,
    REQUESTS_IN_PROGRESS,
    VIEW_BOOKINGS,
} from '../../constans/urlPaths';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import { SCOPES } from '../../permissions/permissionMaps';
import PermissionsGate from '../../permissions/PermissionsGate';
import { Request } from './api/types';
import BookingsList from './booking/BookingsList';
import CreateBooking from './booking/CreateBooking';
import EditBooking from './booking/EditBooking';
import ViewBooking from './booking/ViewBooking';
import CreateRequest from './request/CreateRequest';
import EditRequest from './request/EditRequest';
import RequestsList from './request/RequestsList';
import ViewRequest from './request/ViewRequest';

export enum RequestTab {
    Pending,
    Upcoming,
    InProgress,
    Archive,
}

export enum BookingTab {
    All,
    Pending,
    Upcoming,
    InProgress,
    Archive,
}

interface ViewBookingLocationState {
    id: string;
}

export interface CreateBookingLocationState {
    request: Request;
}

export const pendingRequests: PageDefinition = {
    path: REQUESTS_PENDING,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessRequestsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Requests" link={REQUESTS_PENDING} />,
                    }}
                >
                    <RequestsList tab={RequestTab.Pending} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const upcomingRequests: PageDefinition = {
    path: REQUESTS_UPCOMING,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessRequestsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Requests" link={REQUESTS_UPCOMING} />,
                    }}
                >
                    <RequestsList tab={RequestTab.Upcoming} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const inProgressRequests: PageDefinition = {
    path: REQUESTS_IN_PROGRESS,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessRequestsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Requests" link={REQUESTS_IN_PROGRESS} />,
                    }}
                >
                    <RequestsList tab={RequestTab.InProgress} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const archiveRequests: PageDefinition = {
    path: REQUESTS_ARCHIVE,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessRequestsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Requests" link={REQUESTS_ARCHIVE} />,
                    }}
                >
                    <RequestsList tab={RequestTab.Archive} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const viewRequest: PageDefinition = {
    path: VIEW_REQUESTS,
    Component() {
        const { id = '' } = useParams();
        const { state } = useLocation();
        const requestId = (state as ViewBookingLocationState)?.id ?? id;

        return (
            <PermissionsGate scopes={[SCOPES.canViewRequest]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Requests" link={REQUESTS_PENDING} />
                                <Typography component="span">{`${requestId ? `${BREADCRUMB_DIVIDER}${REQUEST_ID_PREFIX}` : BREADCRUMB_DIVIDER}${id}`}</Typography>
                            </>
                        ),
                    }}
                >
                    <ViewRequest />
                </Page>
            </PermissionsGate>
        );
    },
};

export const createRequest: PageDefinition = {
    path: CREATE_REQUEST,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canCreateRequest]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Requests" link={REQUESTS_PENDING} />
                                <Typography component="span">{BREADCRUMB_DIVIDER}New request</Typography>
                            </>
                        ),
                    }}
                >
                    <CreateRequest />
                </Page>
            </PermissionsGate>
        );
    },
};

export const editRequest: PageDefinition = {
    path: EDIT_REQUEST,
    Component() {
        const { state } = useLocation();
        const requestNumber = (state as EditRequestLocationState)?.requestNumber ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canEditRequest]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Requests" link={REQUESTS_PENDING} />
                                <Typography component="span">{BREADCRUMB_DIVIDER}</Typography>
                                <SimpleLink label={`${REQUEST_ID_PREFIX}${requestNumber}`} link={`${REQUESTS}${requestNumber}`} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}
                                    Update request
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <EditRequest />
                </Page>
            </PermissionsGate>
        );
    },
};

export const createBooking: PageDefinition = {
    path: CREATE_BOOKING,
    Component() {
        const { state } = useLocation();
        const request = (state as CreateBookingLocationState).request ?? null;

        return (
            <PermissionsGate scopes={[SCOPES.canCreateBooking]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Bookings" link={REQUESTS_PENDING} />
                                <Typography component="span">{BREADCRUMB_DIVIDER}Create booking</Typography>
                            </>
                        ),
                    }}
                >
                    <CreateBooking request={request} />
                </Page>
            </PermissionsGate>
        );
    },
};

export interface EditBookingLocationState {
    bookingNumber: string;
}

export const allBookings: PageDefinition = {
    path: BOOKINGS,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessBookingsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Bookings" link={BOOKINGS} />,
                    }}
                >
                    <BookingsList tab={BookingTab.All} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const pendingBookings: PageDefinition = {
    path: BOOKINGS_PENDING,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessBookingsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Bookings" link={BOOKINGS} />,
                    }}
                >
                    <BookingsList tab={BookingTab.Pending} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const upcomingBookings: PageDefinition = {
    path: BOOKINGS_UPCOMING,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessBookingsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Bookings" link={BOOKINGS} />,
                    }}
                >
                    <BookingsList tab={BookingTab.Upcoming} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const inProgressBookings: PageDefinition = {
    path: BOOKINGS_IN_PROGRESS,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessBookingsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Bookings" link={BOOKINGS} />,
                    }}
                >
                    <BookingsList tab={BookingTab.InProgress} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const archivedBookings: PageDefinition = {
    path: BOOKINGS_ARCHIVE,
    Component() {
        return (
            <PermissionsGate scopes={[SCOPES.canAccessBookingsList]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Bookings" link={BOOKINGS} />,
                    }}
                >
                    <BookingsList tab={BookingTab.Archive} />
                </Page>
            </PermissionsGate>
        );
    },
};

export const viewBooking: PageDefinition = {
    path: VIEW_BOOKINGS,
    Component() {
        const { id = '' } = useParams();
        const { state } = useLocation();
        const requestId = (state as ViewBookingLocationState)?.id ?? id;

        return (
            <PermissionsGate scopes={[SCOPES.canViewBooking]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Bookings" link={BOOKINGS} />
                                <Typography component="span">{`${requestId ? `${BREADCRUMB_DIVIDER}${BOOKING_ID_PREFIX}` : BREADCRUMB_DIVIDER}${id}`}</Typography>
                            </>
                        ),
                    }}
                >
                    <ViewBooking />
                </Page>
            </PermissionsGate>
        );
    },
};

export const editBooking: PageDefinition = {
    path: EDIT_BOOKING,
    Component() {
        const { state } = useLocation();
        const bookingNumber = (state as EditBookingLocationState)?.bookingNumber ?? '';

        return (
            <PermissionsGate scopes={[SCOPES.canEditBooking]}>
                <Page
                    breadcrumbs={{
                        name: (
                            <>
                                <SimpleLink label="Bookings" link={BOOKINGS} />
                                <Typography component="span">
                                    {BREADCRUMB_DIVIDER}
                                    {bookingNumber && `${bookingNumber} ${BREADCRUMB_DIVIDER}`}
                                    Update booking
                                </Typography>
                            </>
                        ),
                    }}
                >
                    <EditBooking />
                </Page>
            </PermissionsGate>
        );
    },
};

export interface EditRequestLocationState {
    requestNumber: string;
}
