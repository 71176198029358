import { Valid, Validated } from './Validator';

export default function allValid<T extends object>(input: {
    [K in keyof T]: Validated<T[K], unknown>;
}): input is {
    [K in keyof T]: Valid<T[K]>;
} {
    const values = Object.values<Validated<unknown, unknown>>(input);

    return values.every(value => value.isValid);
}
