import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ALL_STATEMENTS } from '../../../constans/queryKeys';
import { listStatements } from '../../../services/statement';
import { StatementVariables } from './types';

export const useStatements = (variables: StatementVariables) => {
    const { search = '', fromDateLocal, toDateLocal, page, customerId, hotelId } = variables;

    const { data, refetch, isLoading, isRefetching } = useQuery(
        [ALL_STATEMENTS + search + fromDateLocal + toDateLocal + page + hotelId + customerId],
        () => listStatements(variables),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
            cacheTime: 500,
        }
    );

    return useMemo(
        () => ({
            data: data ?? [],
            refetch,
            isLoading,
            isRefetching,
        }),
        [data, isLoading, isRefetching, refetch]
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockData = {
    data: [
        {
            id: 2,
            bookingNumber: 'BN8121489',
            startDate: '2024-10-16',
            endDate: '2024-10-16',
            totalRoomNights: 7,
            totalAmount: 110,
            currencyIso: 'TRY',
            customerName: 'Wizz test',
            hotelName: 'Marriott hotel',
        },
        {
            id: 6,
            bookingNumber: 'BN4474664',
            startDate: '2024-10-16',
            endDate: '2024-10-16',
            totalRoomNights: 7,
            totalAmount: 110,
            currencyIso: 'TRY',
            customerName: 'Wizz test',
            hotelName: 'Marriott hotel',
        },
        {
            id: 3,
            bookingNumber: 'BN9175541',
            startDate: '2024-10-16',
            endDate: '2024-10-16',
            totalRoomNights: 7,
            totalAmount: 110,
            currencyIso: 'TRY',
            customerName: 'Wizz test',
            hotelName: 'Marriott hotel',
        },
        {
            id: 7,
            bookingNumber: 'BN8473517',
            startDate: '2024-10-16',
            endDate: '2024-10-16',
            totalRoomNights: 7,
            totalAmount: 110,
            currencyIso: 'TRY',
            customerName: 'Wizz test',
            hotelName: 'Marriott hotel',
        },
        {
            id: 4,
            bookingNumber: 'BN4137089',
            startDate: '2024-10-16',
            endDate: '2024-10-16',
            totalRoomNights: 7,
            totalAmount: 110,
            currencyIso: 'TRY',
            customerName: 'Wizz test',
            hotelName: 'Marriott hotel',
        },
        {
            id: 1,
            bookingNumber: 'BN8360914',
            startDate: '2024-10-16',
            endDate: '2024-10-16',
            totalRoomNights: 7,
            totalAmount: 110,
            currencyIso: 'TRY',
            customerName: 'Wizz test',
            hotelName: 'Marriott hotel',
        },
        {
            id: 5,
            bookingNumber: 'BN1439872',
            startDate: '2024-10-16',
            endDate: '2024-10-16',
            totalRoomNights: 7,
            totalAmount: 110,
            currencyIso: 'TRY',
            customerName: 'Wizz test',
            hotelName: 'Marriott hotel',
        },
    ],
    links: {
        first: 'http://localhost/api/statements?page=1',
        last: 'http://localhost/api/statements?page=1',
        prev: null,
        next: null,
    },
    meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [
            {
                url: null,
                label: '&laquo; Previous',
                active: false,
            },
            {
                url: 'http://localhost/api/statements?page=1',
                label: '1',
                active: true,
            },
            {
                url: null,
                label: 'Next &raquo;',
                active: false,
            },
        ],
        path: 'http://localhost/api/statements',
        per_page: 50,
        to: 7,
        total: 7,
    },
};
